import React from "react";
import {Card, Typography} from "@mui/material";

import {
	PieChart,
	Pie,
	Legend,
	Tooltip,
	ResponsiveContainer,
	Cell,
} from "recharts";

function OrdersPerPickupChart({data}) {
	return (
		<Card sx={{height: 350}}>
			<Typography variant="h5" sx={{padding: 2}} textAlign={"center"}>
				Σύνολο Παραγγελιών ανά Τρόπο Παραλαβής
			</Typography>
			<ResponsiveContainer width="100%" height="70%">
				<PieChart>
					<Pie dataKey="count" data={data} fill="#8884d8" label>
						{data &&
							data.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={entry.color}
								/>
							))}
					</Pie>
					<Tooltip />
					<Legend />
				</PieChart>
			</ResponsiveContainer>
		</Card>
	);
}

export default OrdersPerPickupChart;
