import {createContext, useContext, useState} from "react";
import {Snackbar, Alert} from "@mui/material";

// Create Context
const SnackbarContext = createContext();

export function useSnackbar() {
	return useContext(SnackbarContext);
}

export function SnackbarProvider({children}) {
	const [snackbarState, setSnackbarState] = useState({
		open: false,
		message: "",
		duration: 6000, // default
		severity: "info", // default
	});

	// To show snackbar
	function showSnackbar(message, duration = 6000, severity = "info") {
		setSnackbarState({open: true, message, duration, severity});
	}

	// To hide snackbar
	function closeSnackbar() {
		setSnackbarState((prev) => ({...prev, open: false}));
	}

	return (
		<SnackbarContext.Provider value={showSnackbar}>
			{children}
			<Snackbar
				open={snackbarState.open}
				autoHideDuration={snackbarState.duration}
				onClose={closeSnackbar}
			>
				<Alert
					onClose={closeSnackbar}
					severity={snackbarState.severity}
					variant="filled"
				>
					{snackbarState.message}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
}
