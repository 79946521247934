import React from "react";
import {Card, Typography} from "@mui/material";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
} from "recharts";

function TotalOrdersChart({data}) {
	return (
		<Card sx={{height: 350}}>
			<Typography variant="h5" sx={{padding: 2}} textAlign={"center"}>
				Σύνολο Παραγγελιών του Μήνα
			</Typography>
			<ResponsiveContainer width="100%" height="80%">
				<LineChart
					data={data}
					margin={{
						top: 15,
						right: 20,
						left: 15,
						bottom: 15,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Line type="monotone" dataKey="count" stroke="#8884d8" />
				</LineChart>
			</ResponsiveContainer>
		</Card>
	);
}

export default TotalOrdersChart;
