import React from "react";
import {useFormContext} from "react-hook-form";
import {TextField} from "@mui/material";

function NameField({disabled}) {
	const {
		register,
		formState: {errors},
	} = useFormContext();
	const error = errors.name;

	return (
		<TextField
			label="Ονοματεπώνυμο"
			variant="standard"
			{...register("name", {
				required: true,
				maxLength: 50,
				pattern: /^[A-Za-zΑ-Ωα-ωάέήίόύώ\s]+$/,
			})}
			InputLabelProps={{
				shrink: true,
				sx: {fontSize: "1.4rem"},
			}}
			InputProps={{
				style: {
					marginTop: 20,
				},
			}}
			sx={{marginBottom: 4}}
			error={!!error}
			helperText={error && "Δώσε ονοματεπώνυμο"}
			fullWidth
			required
			disabled={disabled}
		/>
	);
}

export default NameField;
