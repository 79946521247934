import PropTypes from "prop-types";
import {memo} from "react";
// @mui
import {Box} from "@mui/material";

// ----------------------------------------------------------------------

Scrollbar.propTypes = {
	sx: PropTypes.object,
	children: PropTypes.node,
};

function Scrollbar({children, sx, ...other}) {
	return (
		<Box sx={{overflowX: "auto", ...sx}} {...other}>
			{children}
		</Box>
	);
}

export default memo(Scrollbar);
