import {useState, useEffect, useRef, useCallback} from "react";
import {
	Card,
	Container,
	Button,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import {Typography} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {StaticDatePicker} from "@mui/x-date-pickers/StaticDatePicker";
import {elGR} from "@mui/x-date-pickers/locales";
import "dayjs/locale/el";

import {useSnackbar} from "../../../../contexts/SnackbarProvider";

import DishForm from "./DishForm";
import {DatePicker} from "@mui/x-date-pickers";

function MenuAdd() {
	const [searchParams] = useSearchParams();
	const [menu, setMenu] = useState();
	const dateParam = searchParams.get("date") || dayjs().format("DD-MM-YYYY");
	const [dateValue, setDateValue] = useState(() =>
		dayjs(dateParam, "DD-MM-YYYY")
	);
	const [dishes, setDishes] = useState(null);
	const [orderBefore, setOrderBefore] = useState(null);
	const [deliveryDisabled, setDeliveryDisabled] = useState(false);
	const [restDisabled, setRestDisabled] = useState(false);

	const dishFormRef = useRef(null);

	const showSnackbar = useSnackbar();

	const changeDay = useCallback(
		(newValue) => {
			setDateValue(newValue);
			const date = newValue.format("YYYY-MM-DD");

			if (menu && menu[date]) {
				setDishes(menu[date]);
			} else {
				setDishes(null);
			}

			if (menu && menu[date] && menu[date].orderBefore) {
				setOrderBefore(dayjs(menu[date].orderBefore));
			} else {
				setOrderBefore(null);
			}

			if (menu && menu[date] && menu[date].deliveryDisabled) {
				setDeliveryDisabled(menu[date].deliveryDisabled);
			} else {
				setDeliveryDisabled(false);
			}
			if (menu && menu[date] && menu[date].restDisabled) {
				setRestDisabled(menu[date].restDisabled);
			} else {
				setRestDisabled(false);
			}
		},
		[menu]
	);

	async function fetchData() {
		try {
			const res = await axios.get("/api/menus/full-menu");
			const parsedDishes = parseDishes(res.data);
			setMenu(parsedDishes);
			changeDay(dateValue);
		} catch (error) {
			console.error("Failed to fetch data:", error);
		}
	}
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		changeDay(dateValue);
	}, [menu, dateValue, changeDay]);

	function parseDishes(data) {
		var parsedDishes = {};

		data.dishes.reduce((acc, dish) => {
			const date = dayjs(dish.date).format("YYYY-MM-DD");
			if (acc[date]) {
				acc[date].push(dish);
			} else {
				acc[date] = [dish];
			}
			return acc;
		}, parsedDishes);

		data.dateOptions.forEach((dateOption) => {
			const date = dayjs(dateOption.date).format("YYYY-MM-DD");
			if (dateOption.options.orderBefore && parsedDishes[date]) {
				parsedDishes[date].orderBefore = dayjs(
					dateOption.options.orderBefore
				);
			}
			if (dateOption.options.deliveryDisabled && parsedDishes[date]) {
				parsedDishes[date].deliveryDisabled =
					dateOption.options.deliveryDisabled;
			}
			if (dateOption.options.restDisabled && parsedDishes[date]) {
				parsedDishes[date].restDisabled =
					dateOption.options.restDisabled;
			}
		});

		return parsedDishes;
	}

	const handleSave = async () => {
		try {
			const dishesToSave = dishFormRef.current.getDishes();
			const date = dateValue.format("YYYY-MM-DD");
			axios
				.post("/api/menus/menu", {
					date: date,
					dishes: dishesToSave,
					orderBefore: orderBefore,
					deliveryDisabled: deliveryDisabled,
					restDisabled: restDisabled,
				})
				.then((res) => {
					if (res.data.success) {
						showSnackbar(
							"Το μενού αποθηκεύτηκε επιτυχώς",
							4000,
							"success"
						);
						fetchData();
					} else {
						showSnackbar("Προέκυψε κάποιο σφάλμα", 4000, "error");
					}
				})
				.catch((err) => {
					console.error(err);
					showSnackbar("Προέκυψε κάποιο σφάλμα", 4000, "error");
				});
		} catch (error) {
			console.error(error);
			showSnackbar("Προέκυψε κάποιο σφάλμα", 4000, "error");
		}
	};

	return (
		<Container>
			<Typography variant="h4" gutterBottom>
				Επεξεργασία Μενού
				<Card sx={{mt: 2, fontSize: "initial"}}>
					<LocalizationProvider
						dateAdapter={AdapterDayjs}
						localeText={
							elGR.components.MuiLocalizationProvider.defaultProps
								.localeText
						}
						adapterLocale="el"
					>
						<div style={{maxWidth: "320px", margin: "auto"}}>
							<StaticDatePicker
								// disablePast
								orientation="portrait"
								value={dateValue}
								onChange={(newValue) => changeDay(newValue)}
								slotProps={{
									actionBar: {
										actions: [],
									},
								}}
								sx={{
									width: {
										xs: 280,
										sm: 320,
									},
								}}
							/>
						</div>
					</LocalizationProvider>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							gap: "1rem",
							flexDirection: "column",
							alignItems: "center",
							marginBottom: "1rem",
						}}
					>
						<DishForm dishes={dishes} ref={dishFormRef} />

						<LocalizationProvider
							dateAdapter={AdapterDayjs}
							localeText={
								elGR.components.MuiLocalizationProvider
									.defaultProps.localeText
							}
							adapterLocale="el"
						>
							<div style={{maxWidth: "320px", margin: "auto"}}>
								<DatePicker
									label="Τελευταία ημερα παραγγελιών"
									value={orderBefore}
									onChange={(newValue) =>
										setOrderBefore(newValue)
									}
									// maxDate dateValue - 1 day
									maxDate={dateValue.subtract(1, "day")}
									disableHighlightToday
								/>
							</div>
						</LocalizationProvider>
						{/* if day is saturday or sunday, don't show */}
						{dateValue.day() !== 6 && dateValue.day() !== 0 && (
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={deliveryDisabled}
											onChange={(e) =>
												setDeliveryDisabled(
													e.target.checked
												)
											}
										/>
									}
									label="Απενεργοποίηση παραγγελιών Αναψυκτηρίου & ΔΣΣ"
								/>
							</FormGroup>
						)}
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={restDisabled}
										onChange={(e) =>
											setRestDisabled(e.target.checked)
										}
									/>
								}
								label="Απενεργοποίηση παραγγελιών Εστιατορίου"
							/>
						</FormGroup>

						<Button
							variant="contained"
							color="primary"
							onClick={handleSave}
						>
							Αποθηκευση
						</Button>
					</div>
				</Card>
			</Typography>
		</Container>
	);
}

export default MenuAdd;
