// @mui
import {styled, alpha} from "@mui/material/styles";
import {
	Toolbar,
	Tooltip,
	IconButton,
	Typography,
	OutlinedInput,
	InputAdornment,
	Switch,
	FormControlLabel,
} from "@mui/material";
// component
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

// ----------------------------------------------------------------------

const transparent = alpha("#919EAB", 0.16);

const StyledRoot = styled(Toolbar)(({theme}) => ({
	height: 96,
	display: "flex",
	justifyContent: "space-between",
	padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({theme}) => ({
	width: 240,
	transition: theme.transitions.create(["box-shadow", "width"], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
	"&.Mui-focused": {
		width: 320,
		boxShadow: `0 8px 16px 0 ${transparent}`,
	},
	"& fieldset": {
		borderWidth: `1px !important`,
		borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
	},
}));

// ----------------------------------------------------------------------

export default function MenuListToolbar({
	numSelected,
	filterName,
	onFilterName,
	switchState,
	handleSwitchState,
	handleMultipleDelete,
}) {
	return (
		<StyledRoot
			sx={{
				...(numSelected > 0 && {
					color: "primary.main",
					bgcolor: "primary.lighter",
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography component="div" variant="subtitle1">
					{numSelected} επιλεγμένα
				</Typography>
			) : (
				<StyledSearch
					value={filterName}
					onChange={onFilterName}
					placeholder="Αναζήτηση πιάτου..."
					startAdornment={
						<InputAdornment position="start">
							<SearchIcon
								sx={{
									color: "text.disabled",
									width: 20,
									height: 20,
								}}
							/>
						</InputAdornment>
					}
				/>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Διαγραφή">
					<IconButton onClick={handleMultipleDelete}>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<FormControlLabel
					control={
						<Switch
							checked={switchState}
							onChange={handleSwitchState}
						/>
					}
					label="Κατά Ημερομηνία"
				/>
			)}
		</StyledRoot>
	);
}
