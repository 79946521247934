import React, {useEffect, useState, useRef} from "react";
import {
	Table,
	TablePagination,
	Checkbox,
	IconButton,
	TableRow,
	TableCell,
	Typography,
	MenuItem,
	TableBody,
	Paper,
	Container,
	Stack,
	Button,
	Card,
	TableContainer,
	Menu,
	Dialog,
	AppBar,
	Toolbar,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	Divider,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import {MenuListHead} from "./components/menu";
import dayjs from "dayjs";
import {filter} from "lodash";
import ReactToPrint from "react-to-print";

import DeleteConfirmationDialog from "../input/DeleteConfirmationDialog";
import DynamicDialog from "./components/DynamicDialog";
import {useSnackbar} from "../../contexts/SnackbarProvider";
import {
	OrderListToolbar,
	ReceiptCard,
	TotalsTable,
	ListTable,
} from "./components/orders";

import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DateField} from "@mui/x-date-pickers/DateField";
import {elGR} from "@mui/x-date-pickers/locales";
import "dayjs/locale/el";

// components
import Scrollbar from "./components/scrollbar";
import axios from "axios";

import "./components/orders/style.css";

const TABLE_HEAD = [
	{id: "name", label: "Όνομα", alignRight: false},
	{id: "email", label: "Email", alignRight: false},
	{id: "phone", label: "Τηλέφωνο", alignRight: false},
	{id: "date", label: "Ημερομηνία", alignRight: false},
	{id: "pickup", label: "Παραλαβή", alignRight: false},
	{id: ""},
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function getDailyDishes(dishes, date) {
	return dishes.filter((dish) => {
		return (
			dayjs(dish.date).format("DD/MM/YYYY") ===
			dayjs(date).format("DD/MM/YYYY")
		);
	});
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	if (query) {
		return filter(
			stabilizedThis.map((el) => el[0]), // Filter the stabilized array
			(_order) => {
				const loweredQuery = query.toLowerCase();
				return (
					(_order.name &&
						_order.name.toLowerCase().indexOf(loweredQuery) !==
							-1) ||
					(_order.email &&
						_order.email.toLowerCase().indexOf(loweredQuery) !==
							-1) ||
					(_order.phone &&
						_order.phone.toString().indexOf(loweredQuery) !== -1)
				);
			}
		);
	}
	return stabilizedThis.map((el) => el[0]);
}

function Orders() {
	const [order, setOrder] = useState("desc");
	const [fullMenu, setFullMenu] = useState({
		dishes: [],
		sides: [],
		dateOptions: [],
	});
	const [orderBy, setOrderBy] = useState("date");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [targetOrderCode, setTargetOrderCode] = useState(null);
	const [filterName, setFilterName] = useState("");
	const [selected, setSelected] = useState([]);
	const [orders, setOrders] = useState([]);
	const [anchorPrintMenu, setAnchorPrintMenu] = useState();
	const [openDateDialog, setOpenDateDialog] = useState(false);
	const [openPrintDialog, setOpenPrintDialog] = useState(false);
	const [openPriceDialog, setOpenPriceDialog] = useState(false);
	const [printDialogData, setPrintDialogData] = useState({});
	const [printDate, setPrintDate] = useState(null);
	const [priceFormFields, setPriceFormFields] = useState([]);
	const [triggerUpdate, setTriggerUpdate] = useState(false);

	const componentRef = useRef();

	const showSnackbar = useSnackbar();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handlePrintClick = (event) => {
		setAnchorPrintMenu(event.currentTarget);
	};

	const handlePrintClose = () => {
		setAnchorPrintMenu(null);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = orders.map((n) => n.code);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const handleOpenDeleteDialog = (id) => {
		setTargetOrderCode(id);
		setOpenDeleteDialog(true);
	};

	const handleDelete = () => {
		// Use targetDishId to delete the dish, e.g., by making an API call.
		axios
			.delete(`/api/orders/delete-order/${targetOrderCode}`)
			.then((response) => {
				if (response.status === 200) {
					showSnackbar("Η παραγγελία διαγράφηκε", 4000, "success");
					fetchOrders();
					return;
				}
				showSnackbar(response.data.message, 4000, "error");
			})
			.catch((error) => {
				showSnackbar(error.message, 4000, "error");
			});

		// After the deletion, clear the targetDishId and close the dialog
		setTargetOrderCode(null);
		setOpenDeleteDialog(false);
	};

	const handleMultipleDelete = () => {
		if (selected.length > 3) {
			showSnackbar(
				"Μπορείτε να διαγράψετε το πολύ 3 παραγγελίες ταυτόχρονα",
				4000,
				"error"
			);
		}
		axios
			.delete("/api/orders/delete-orders", {data: {orders: selected}})
			.then((response) => {
				if (response.data.success) {
					showSnackbar("Οι παραγγελίες διαγράφηκαν", 4000, "success");
					fetchOrders();
					return;
				}
				showSnackbar(response.data.message, 4000, "error");
			})
			.catch((error) => {
				showSnackbar(error.message, 4000, "error");
			});

		setSelected([]);
	};

	const fetchOrders = async () => {
		const lastTwoMonths = dayjs().subtract(2, "month");
		console.log(lastTwoMonths);
		// format date to YYYY-MM-DD
		axios
			.get("/api/orders", {
				params: {after: lastTwoMonths.format("YYYY-MM-DD")},
			})
			.then((response) => {
				if (response.data.length === 0) {
					showSnackbar("Δεν υπάρχουν παραγγελίες", 4000, "error");
				}

				if (response.status === 200) {
					setOrders(response.data);
					return;
				}

				showSnackbar(response.data.message, 4000, "error");
			})
			.catch((error) => {
				showSnackbar(error.message, 4000, "error");
			});
	};

	const fetchMenu = async () => {
		axios
			.get("/api/menus/full-menu")
			.then((response) => {
				if (response.status === 200) {
					setFullMenu(response.data);
					return;
				}
				showSnackbar(response.data.message, 4000, "error");
			})
			.catch((error) => {
				showSnackbar(error.message, 4000, "error");
			});
	};

	const handleDataReset = () => {
		setOpenDateDialog(false);
		setPrintDate(null);
		setPrintDialogData({});
		setTriggerUpdate(false);
		setOpenPrintDialog(false);
		setOpenPriceDialog(false);
	};

	const preparePriceFormFields = async () => {
		try {
			const response = await axios.get("/api/menus/daily-menu", {
				params: {date: printDialogData.date.format("DD-MM-YYYY")},
			});

			if (response.status !== 200) {
				showSnackbar(response.data.message, 4000, "error");
				return;
			}

			const dishes = getDailyDishes(
				fullMenu.dishes,
				printDialogData.date
			);
			let fields = [];
			dishes.forEach((dish) => {
				fields.push({
					name: dish._id + "_package_price",
					label: dish.name + " (πακέτο)",
					type: "text",
					value: dish.package_price,
				});
				fields.push({
					name: dish._id + "_restaurant_price",
					label: dish.name + " (εστιατόριο)",
					type: "text",
					value: dish.restaurant_price,
				});
			});

			setPriceFormFields(fields);
			setPrintDialogData((o) => ({
				...o,
				dishes: dishes,
			}));
			setOpenPriceDialog(true);
		} catch (error) {
			showSnackbar(error.message, 4000, "error");
		}
	};

	const fetchDailyMenu = async () => {
		try {
			const response = await axios
				.get("/api/menus/daily-menu", {
					params: {date: printDialogData.date.format("DD-MM-YYYY")},
				})
				.then((response) => {
					if (response.status === 200) {
						setPrintDialogData((o) => ({
							...o,
							dishes: response.data.dishes,
						}));
						return;
					}
					showSnackbar(response.data.message, 4000, "error");
				})
				.catch((error) => {
					showSnackbar(error.message, 4000, "error");
				});
		} catch (error) {
			showSnackbar(error.message, 4000, "error");
		}
	};

	useEffect(() => {
		fetchOrders();
		fetchMenu();
	}, []);

	const getType = (type) => {
		switch (type) {
			case "LAF Xanthis":
				return "Εστιατόριο";
			case "LAF Xanthis Pickup":
				return "Εστιατόριο Πακέτο";
			case "Anapsiktirio Pickup":
				return "Αναψυκτήριο Πακέτο";
			case "DSS Pickup":
				return "ΔΣΣ Πακέτο";
			default:
				return "-";
		}
	};

	const updatePrintDialogOrders = () => {
		if (!printDialogData.date) {
			showSnackbar("Δεν έχει επιλεγεί ημερομηνία", 4000, "error");
			return;
		}

		let filteredOrders = orders.filter((order) => {
			return (
				dayjs(order.date).format("DD/MM/YYYY") ===
				dayjs(printDialogData.date).format("DD/MM/YYYY")
			);
		});

		setPrintDialogData((o) => ({
			...o,
			orders: filteredOrders,
		}));
	};

	function getStyles() {
		// Get styles from all style tags (or more specific selector if needed)
		let styles = Array.from(document.querySelectorAll("style"))
			.map((style) => style.innerHTML)
			.join("");
		// set background color to white
		styles += "body { background: white; }";
		return styles;
	}

	const printContent = () => {
		const printContent = document.getElementById("printableArea").innerHTML;
		const printStyles = getStyles();
		// set
		const fullContent = `<html><head><style>${printStyles}</style></head><body>${printContent}</body></html>`;
		let iframe = document.createElement("iframe");
		document.body.appendChild(iframe);

		iframe.contentDocument.write(fullContent);
		iframe.contentDocument.close();

		setTimeout(() => {
			iframe.contentWindow.print();
			document.body.removeChild(iframe);
		}, 500);
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;
	const filteredOrders = applySortFilter(
		orders,
		getComparator(order, orderBy),
		filterName
	);
	const isNotFound = !filteredOrders.length && !!filterName;

	useEffect(() => {
		if (triggerUpdate) {
			updatePrintDialogOrders();
		}
	}, [triggerUpdate]);

	useEffect(() => {
		if (printDialogData.orders && triggerUpdate) {
			setOpenDateDialog(false);
			if (printDialogData.type === "receipts") {
				preparePriceFormFields();
				return;
			}
			if (printDialogData.type === "list") {
				setPrintDialogData((o) => ({
					...o,
					dishes: getDailyDishes(
						fullMenu.dishes,
						printDialogData.date
					),
				}));
				generateExcel();
				return;
			}
		}
		setTriggerUpdate(false);
	}, [printDialogData.orders]);

	const generateExcel = async () => {
		try {
			const response = await axios.post(
				"/api/orders/generate-excel",
				{
					data: {
						...printDialogData,
						sides: fullMenu.sides,
						dishes: getDailyDishes(
							fullMenu.dishes,
							printDialogData.date
						),
					},
				},
				{responseType: "blob"}
			);

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				`${printDialogData.date.format("DD-MM-YYYY")}.xlsx`
			);
			document.body.appendChild(link);
			link.click();
			window.URL.revokeObjectURL(url);
			document.body.removeChild(link);
			setOpenPrintDialog(false);
			handleDataReset();
		} catch (error) {
			console.error("Excel download failed:", error);
		}
	};

	const handlePriceSubmit = (data) => {
		// check if all prices are filled and are numbers
		let dishesToUpdate = {};
		for (const [key, value] of Object.entries(data)) {
			if (!value) {
				showSnackbar("Συμπληρώστε όλες τις τιμές", 4000, "error");
				return;
			}
			if (isNaN(value)) {
				showSnackbar("Οι τιμές πρέπει να είναι αριθμοί", 4000, "error");
				return;
			}
			const parts = key.split("_");
			const dishId = parts[0];
			const priceType = parts.slice(1).join("_");

			dishesToUpdate[dishId] = {
				...dishesToUpdate[dishId],
				[priceType]: value,
			};
		}
		axios
			.post("/api/dishes/update-dishes", {dishes: dishesToUpdate})
			.then((response) => {
				if (response.data.success) {
					showSnackbar("Οι τιμές ενημερώθηκαν", 4000, "success");
					fetchMenu();
				} else {
					showSnackbar(response.data.message, 4000, "error");
				}
			})
			.catch((error) => {
				showSnackbar(error.message, 4000, "error");
			});

		// setPrintDialogData((o) => ({
		// 	...o,
		// 	prices: data,
		// }));

		setOpenPriceDialog(false);
		setOpenPrintDialog(true);
	};

	const table = (
		<>
			<Table>
				<MenuListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={orders.length}
					numSelected={selected.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{filteredOrders
						.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)
						.map((row) => {
							const {code, name, email, phone, date, pickup} =
								row;
							const selectedDish = selected.indexOf(code) !== -1;

							return (
								<TableRow
									hover
									key={code}
									tabIndex={-1}
									role="checkbox"
									selected={selectedDish}
								>
									<TableCell padding="checkbox">
										<Checkbox
											checked={selectedDish}
											onChange={(event) =>
												handleClick(event, code)
											}
										/>
									</TableCell>

									<TableCell
										component="th"
										scope="row"
										padding="none"
									>
										<Typography variant="subtitle2" noWrap>
											{name}
										</Typography>
									</TableCell>

									<TableCell align="left">
										{email || "-"}
									</TableCell>

									<TableCell align="left">
										{phone || "-"}
									</TableCell>

									<TableCell align="left">
										{dayjs(date).format("DD/MM/YYYY") ||
											"-"}
									</TableCell>

									<TableCell align="left">
										{getType(pickup)}
									</TableCell>

									{/* <TableCell align="right">
										<IconButton
											size="large"
											color="inherit"
											onClick={handleOpenMenu}
										>
											<MoreVertIcon />
										</IconButton>
									</TableCell> */}
									<TableCell align="right">
										<IconButton
											// size="large"
											sx={{color: "info.main"}}
											// onClick={handleEditDish(_id)}
											component="a"
											href={`/view/${code}`}
											target="_blank"
										>
											<VisibilityIcon />
										</IconButton>
										<IconButton
											// size="large"
											sx={{color: "error.main"}}
											onClick={() =>
												handleOpenDeleteDialog(code)
											}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
				{emptyRows > 0 && (
					<TableRow style={{height: 53 * emptyRows}}>
						<TableCell colSpan={6} />
					</TableRow>
				)}

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{py: 3}}>
								<Paper
									sx={{
										textAlign: "center",
										boxShadow: "none",
									}}
								>
									<Typography variant="h6" paragraph>
										Δεν βρέθηκαν παραγγελίες
									</Typography>

									<Typography variant="body2">
										Δεν βρέθηκαν παραγγελίες για &nbsp;
										<strong>
											&quot;{filterName}
											&quot;
										</strong>
										.
										<br /> Ελέγξτε την ορθότητα της
										αναζήτησης ή δοκιμάστε να
										χρησιμοποιήσετε έναν διαφορετικό όρο
										αναζήτησης.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>

			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={orders.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="Παραγγελίες ανά σελίδα"
				labelDisplayedRows={({from, to, count}) =>
					`${from}-${to} από ${count}`
				}
			/>
		</>
	);

	return (
		<div className="orders">
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Παραγγελίες
					</Typography>
					<Button
						variant="contained"
						startIcon={<PrintIcon />}
						onClick={handlePrintClick}
					>
						Εκτύπωση καταλόγου
					</Button>

					<Menu
						anchorEl={anchorPrintMenu}
						open={Boolean(anchorPrintMenu)}
						onClose={handlePrintClose}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
					>
						<MenuItem
							onClick={() => {
								// something
								handlePrintClose();
								setPrintDialogData({
									title: "Κατάλογος Παραγγελιών",
									description:
										"Εκτύπωση καταλόγου παραγγελιών",
									type: "list",
								});
								setOpenDateDialog(true);
							}}
						>
							Κατάλογος Παραγγελιών
						</MenuItem>

						<MenuItem
							onClick={() => {
								// something
								handlePrintClose();
								setPrintDialogData({
									title: "Αποδείξεις Παραγγελιών",
									description:
										"Εκτύπωση αποδείξεων παραγγελιών",
									type: "receipts",
								});
								setOpenDateDialog(true);
							}}
						>
							Αποδείξεις Παραγγελιών
						</MenuItem>
					</Menu>
				</Stack>

				<Card>
					<OrderListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
						handleMultipleDelete={handleMultipleDelete}
					/>

					<Scrollbar>
						<TableContainer sx={{minWidth: 800}}>
							{table}
						</TableContainer>
					</Scrollbar>
				</Card>
				<DeleteConfirmationDialog
					open={openDeleteDialog}
					onClose={() => setOpenDeleteDialog(false)}
					onConfirm={handleDelete}
					title="Διαγραφή παραγγελίας"
					description="Είστε σίγουρος για την διαγραφή της παραγγελίας;"
				/>

				<Dialog open={openDateDialog} onClose={handleDataReset}>
					<DialogTitle>Επιλογή ημερομηνίας</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Επιλέξτε ημερομηνία για εκτύπωση καταλόγου{" "}
							{printDialogData.title || ""}
						</DialogContentText>
						<div style={{marginTop: "1rem", textAlign: "center"}}>
							<LocalizationProvider
								dateAdapter={AdapterDayjs}
								localeText={
									elGR.components.MuiLocalizationProvider
										.defaultProps.localeText
								}
								adapterLocale="el"
							>
								<DateField
									label="Ημερομηνία"
									value={printDate}
									onChange={(date) => setPrintDate(date)}
									format="DD/MM/YYYY"
								/>
							</LocalizationProvider>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleDataReset}>Ακυρωση</Button>
						<Button
							onClick={() => {
								if (
									getDailyDishes(fullMenu.dishes, printDate)
										.length === 0
								) {
									showSnackbar(
										"Δεν υπάρχουν πιάτα για την επιλεγμένη ημερομηνία",
										4000,
										"error"
									);
									return;
								}
								setPrintDialogData((o) => ({
									...o,
									date: printDate,
								}));
								setTriggerUpdate(true);
							}}
							autoFocus
							variant="contained"
						>
							Επιλογη
						</Button>
					</DialogActions>
				</Dialog>

				<DynamicDialog
					open={openPriceDialog}
					handleClose={handleDataReset}
					title="Τιμολόγηση"
					description="Εισάγετε τις τιμές πιάτων"
					formFields={priceFormFields}
					onSubmit={handlePriceSubmit}
				/>

				<Dialog
					fullScreen
					open={openPrintDialog}
					onClose={handleDataReset}
				>
					<AppBar sx={{position: "relative"}}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={handleDataReset}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
							<Typography
								sx={{ml: 2, flex: 1}}
								variant="h6"
								component="div"
							>
								{printDialogData.title || ""}
							</Typography>
							<ReactToPrint
								trigger={() => (
									<Button autoFocus color="inherit">
										Εκτύπωση
									</Button>
								)}
								content={() => componentRef.current}
							/>
						</Toolbar>
					</AppBar>
					<Container
						fixed
						maxWidth="xl"
						id="printableArea"
						style={{textAlign: "center"}}
						ref={componentRef}
					>
						{printDialogData.orders && (
							<>
								{printDialogData.type === "receipts" && (
									<>
										{printDialogData.orders.map(
											(order, index) => (
												<React.Fragment key={order._id}>
													<ReceiptCard
														order={order}
														dishes={fullMenu.dishes}
														sides={fullMenu.sides}
														printData={
															printDialogData
														}
													/>
													<Divider
														sx={{
															borderStyle:
																"dashed",
															borderColor:
																"black",
														}}
													/>
												</React.Fragment>
											)
										)}
										<TotalsTable
											printData={printDialogData}
											fullMenu={fullMenu}
										/>
									</>
								)}
								{printDialogData.type === "list" && (
									<>
										<ListTable
											printData={printDialogData}
											fullMenu={fullMenu}
										/>
									</>
								)}
							</>
						)}
					</Container>
				</Dialog>
			</Container>
		</div>
	);
}

export default Orders;
