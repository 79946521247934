import {useState} from "react";
import {
	Table,
	TablePagination,
	Checkbox,
	IconButton,
	TableRow,
	TableCell,
	Typography,
	TableBody,
	Paper,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {MenuListHead} from ".";
import dayjs from "dayjs";
import {filter} from "lodash";

import DeleteConfirmationDialog from "../../../input/DeleteConfirmationDialog";
import {useSnackbar} from "../../../../contexts/SnackbarProvider";
import axios from "axios";

const TABLE_HEAD = [
	{id: "name", label: "Όνομα", alignRight: false},
	{id: "date", label: "Ημερομηνία", alignRight: false},
	{id: "type", label: "Τύπος", alignRight: false},
	{id: ""},
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) return -1;

	if (b[orderBy] > a[orderBy]) return 1;

	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	if (query) {
		return filter(
			array,
			(_menu) =>
				_menu.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
		);
	}
	return stabilizedThis.map((el) => el[0]);
}

function MenuByDish({menu, filterName, selected, setSelected, refreshMenu}) {
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("date");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [targetDishId, setTargetDishId] = useState(null);

	const showSnackbar = useSnackbar();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = menu.map((n) => n._id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, _id) => {
		const selectedIndex = selected.indexOf(_id);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, _id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleOpenDeleteDialog = (id) => {
		setTargetDishId(id);
		setOpenDeleteDialog(true);
	};

	const handleDelete = () => {
		// Use targetDishId to delete the dish, e.g., by making an API call.
		axios
			.delete(`/api/dishes/${targetDishId}`)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
					showSnackbar("Το πιάτο διαγράφηκε", 4000, "success");
					refreshMenu();
				} else {
					showSnackbar("Κάτι πήγε στραβά", 4000, "error");
				}
			})
			.catch((err) => {
				console.log(err);
			});

		// After the deletion, clear the targetDishId and close the dialog
		setTargetDishId(null);
		setOpenDeleteDialog(false);
	};

	const getType = (type) => {
		switch (type) {
			case "main":
				return "Κυρίως";
			case "pescatarian":
				return "Νηστίσιμο";
			case "dessert":
				return "Γλυκό";
			default:
				return "-";
		}
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - menu.length) : 0;
	const filteredMenu = applySortFilter(
		menu,
		getComparator(order, orderBy),
		filterName
	);
	const isNotFound = !filteredMenu.length && !!filterName;

	return (
		<>
			<Table>
				<MenuListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={menu.length}
					numSelected={selected.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{filteredMenu
						.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)
						.map((row) => {
							const {_id, name, date, type} = row;
							const selectedDish = selected.indexOf(_id) !== -1;

							return (
								<TableRow
									hover
									key={_id}
									tabIndex={-1}
									role="checkbox"
									selected={selectedDish}
								>
									<TableCell padding="checkbox">
										<Checkbox
											checked={selectedDish}
											onChange={(event) =>
												handleClick(event, _id)
											}
										/>
									</TableCell>

									<TableCell
										component="th"
										scope="row"
										padding="none"
									>
										<Typography variant="subtitle2" noWrap>
											{name}
										</Typography>
									</TableCell>

									<TableCell align="left">
										{dayjs(date).format("DD/MM/YYYY") ||
											"-"}
									</TableCell>

									<TableCell align="left">
										{getType(type)}
									</TableCell>

									<TableCell align="right">
										<IconButton
											sx={{color: "error.main"}}
											onClick={() =>
												handleOpenDeleteDialog(_id)
											}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
				{emptyRows > 0 && (
					<TableRow style={{height: 53 * emptyRows}}>
						<TableCell colSpan={6} />
					</TableRow>
				)}

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{py: 3}}>
								<Paper
									sx={{
										textAlign: "center",
										boxShadow: "none",
									}}
								>
									<Typography variant="h6" paragraph>
										Δεν βρέθηκαν πιάτα
									</Typography>

									<Typography variant="body2">
										Δεν βρέθηκαν πιάτα για &nbsp;
										<strong>
											&quot;{filterName}
											&quot;
										</strong>
										.
										<br /> Ελέγξτε την ορθότητα της
										αναζήτησης ή δοκιμάστε να
										χρησιμοποιήσετε έναν διαφορετικό όρο
										αναζήτησης.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>

			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={menu.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="Πιάτα ανά σελίδα"
				labelDisplayedRows={({from, to, count}) =>
					`${from}-${to} από ${count}`
				}
			/>
			<DeleteConfirmationDialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onConfirm={handleDelete}
				title="Διαγραφή πιάτου"
				description="Είστε σίγουρος για την διαγραφή του πιάτου;"
			/>
		</>
	);
}

export default MenuByDish;
