import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function DeleteConfirmationDialog({
	open,
	onClose,
	onConfirm,
	title = "Ακύρωση παραγγελίας",
	description = "Είστε σίγουροι ότι θέλετε να ακυρώσετε την παραγγελία;",
}) {
	const handleConfirm = () => {
		// If a valid onConfirm callback function is passed, execute it.
		if (onConfirm && typeof onConfirm === "function") {
			onConfirm();
		}
		onClose(); // close the dialog after confirmation
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="primary">
						Πισω
					</Button>
					<Button onClick={handleConfirm} color="primary" autoFocus>
						Ακυρωση
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
