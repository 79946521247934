import React from "react";

import {Card, CardContent, Typography} from "@mui/material";

function ReceiptCard({order, dishes, sides, printData}) {
	const getType = (type) => {
		switch (type) {
			case "LAF Xanthis":
				return "Εστιατόριο";
			case "LAF Xanthis Pickup":
				return "Εστιατόριο Πακέτο";
			case "Anapsiktirio Pickup":
				return "Αναψυκτήριο Πακέτο";
			case "DSS Pickup":
				return "Δ'ΣΣ Πακέτο";
			default:
				return "-";
		}
	};

	const getDish = (id) => {
		const dish = dishes.find((dish) => dish._id === id);
		if (dish) {
			return dish;
		}
		const side = sides.find((side) => side._id === id);
		if (side) {
			return side;
		}
		return false;
	};

	const getPrice = (id) => {
		const dishPrice = getDish(id);
		if (!dishPrice) {
			return 0;
		}
		return order.pickup === "LAF Xanthis"
			? dishPrice.restaurant_price
			: dishPrice.package_price;
	};

	function Price({value}) {
		const formattedValue = new Intl.NumberFormat("el-GR", {
			style: "currency",
			currency: "EUR",
		}).format(value);

		return <span>{formattedValue}</span>;
	}

	const getTotalDishesPrice = () => {
		let total = 0;
		order.dishes.forEach((dish) => {
			total += getPrice(dish.dish) * dish.quantity;
		});
		return total;
	};

	const getTotalSidesPrice = () => {
		let total = 0;
		order.sides.forEach((side) => {
			total += getPrice(side.side) * side.quantity;
		});
		return total;
	};

	return (
		<Card
			sx={{margin: "12px auto", width: 500, border: "1px solid black"}}
			elevation={0}
		>
			<CardContent
				sx={{padding: 0, textAlign: "center", ":last-child": {pb: 0}}}
			>
				<Typography variant="h6" sx={{backgroundColor: "#ddd"}}>
					ΛΑΦ ΞΑΝΘΗΣ
				</Typography>
				<Typography sx={{fontSize: 14, mt: 1}}>
					{getType(order.pickup)} {order.dss && `(${order.dss})`}
				</Typography>
				<Typography sx={{fontSize: 16, mt: 1, fontWeight: "bold"}}>
					{order.name}
				</Typography>
				{order.dishes.map((dish) => {
					return (
						<Typography sx={{fontSize: 14, mt: 1}} key={dish.dish}>
							{`${dish.quantity}x `}
							{getDish(dish.dish).name}
							{" - "}
							<Price
								value={getPrice(dish.dish) * dish.quantity}
							/>
						</Typography>
					);
				})}
				{order.sides.map((side) => {
					return (
						<Typography sx={{fontSize: 14, mt: 1}}>
							{`${side.quantity}x `}
							{getDish(side.side).name}
							{" - "}
							<Price
								value={getPrice(side.side) * side.quantity}
							/>
						</Typography>
					);
				})}
				{order.notes && (
					<Typography sx={{fontSize: 14, mt: 1}}>
						{<span style={{fontWeight: "bold"}}>Σημειώσεις: </span>}
						{order.notes}
					</Typography>
				)}
				<Typography sx={{fontSize: 14, mt: 1}}>
					{`Σύνολο: `}
					<span style={{fontWeight: "bold"}}>
						<Price value={getTotalDishesPrice()} />
					</span>{" "}
					Συσσιτίου +{" "}
					<span style={{fontWeight: "bold"}}>
						<Price value={getTotalSidesPrice()} />
					</span>{" "}
					Της Ώρας ={" "}
					<span style={{fontWeight: "bold"}}>
						<Price
							value={getTotalDishesPrice() + getTotalSidesPrice()}
						/>
					</span>
				</Typography>
			</CardContent>
		</Card>
	);
}

export default ReceiptCard;
