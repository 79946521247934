import {useState, useEffect} from "react";
import {
	Button,
	InputAdornment,
	TextField,
	Grid,
	Typography,
} from "@mui/material";
import {styled} from "@mui/system";

const StyledButton = styled(Button)({
	padding: "0.7rem",
	width: "2.5rem",
	fontSize: "1.2rem",
	background: "#f3f3f3",
	color: "#888",
	border: "0 solid #dbdbdb",
	textAlign: "center",
	textShadow: "0 1px 0 rgba(255, 255, 255, 0.6)",
	cursor: "pointer",
	"&:hover": {
		background: "#dadada",
		color: "#555555",
		border: "none",
	},
	outline: "none",
	// last child
	"&:last-of-type": {
		borderRadius: "0 3px 3px 0",
	},
	// first child
	"&:first-of-type": {
		borderRadius: "3px 0 0 3px",
	},
	minWidth: "3rem",
	lineHeight: "1.15",
});

const StyledTextField = styled(TextField)({
	margin: "0",
	width: "6rem",
	padding: "0",
	fontSize: "16px",
	border: 0,
	borderTop: "0 solid #dbdbdb",
	borderBottom: "0 solid #dbdbdb",
	textAlign: "center",
	userSelect: "none",
	outline: "none",
	"& .MuiInputBase-input": {
		padding: "10.5px 14px",
	},
});

// You can customize this component to style the label as you see fit.
const Label = styled(Typography)({
	// Example styles
	marginRight: "1rem",
	// fontWeight: "bold",
});

function QuantityInput({
	defaultValue = 0,
	maxValue = 9,
	minValue = 0,
	disabled = false,
	label,
	onChange,
}) {
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		if (onChange) {
			onChange(value);
		}
	}, [value, onChange]);

	function increment() {
		if (value >= maxValue) {
			return;
		}
		const newValue = value + 1;
		setValue(newValue);
		onChange(newValue); // Update here
	}

	function decrement() {
		if (value <= minValue) {
			return;
		}
		const newValue = value - 1;
		setValue(newValue);
		onChange(newValue); // Update here
	}

	return (
		<Grid
			container
			display="flex"
			alignItems="center"
			justifyContent={"space-between"}
			sx={{marginBottom: "1rem"}}
		>
			<Grid item xs={12} sm={5} md>
				<Label>{label}</Label>
			</Grid>
			<Grid item width={200}>
				<StyledButton
					variant="outlined"
					onClick={decrement}
					sx={{visibility: disabled ? "hidden" : "visible"}}
				>
					-
				</StyledButton>
				<StyledTextField
					InputProps={{
						readOnly: true,
						endAdornment: (
							<InputAdornment position="end">Μερ.</InputAdornment>
						),
					}}
					value={value}
					variant="outlined"
					sx={{border: "none"}}
					onChange={onChange}
					disabled={disabled}
				/>
				<StyledButton
					variant="outlined"
					onClick={increment}
					sx={{visibility: disabled ? "hidden" : "visible"}}
				>
					+
				</StyledButton>
			</Grid>
		</Grid>
	);
}

export default QuantityInput;
