import dayjs from "dayjs";

export function isToday(dateValue) {
	return dayjs(dateValue).isSame(dayjs(), "day");
}

export function isAfterToday(dateValue) {
	return dayjs(dateValue).isAfter(dayjs(), "day");
}

export function isBeforeToday(dateValue) {
	return dayjs(dateValue).isBefore(dayjs(), "day");
}

export function isPastNineToday() {
	const now = dayjs();
	const nineToday = dayjs().hour(9).minute(0).second(0);
	return now.isAfter(nineToday);
}

export function isPastTenToday() {
	const now = dayjs();
	const tenToday = dayjs().hour(10).minute(0).second(0);
	return now.isAfter(tenToday);
}

export function isTomorrow(dateValue) {
	const tomorrow = dayjs().add(1, "day");
	return dayjs(dateValue).isSame(tomorrow, "day");
}

export function checkDisabled(
	menuItems,
	dateValue = dayjs(),
	customLastDate = null,
	isAdmin = false
) {
	if (!menuItems || menuItems.length <= 0) {
		// console.log("checkDisabled: menuItems is empty");
		return true;
	}

	if (isBeforeToday(dateValue)) {
		// console.log("checkDisabled: isBeforeToday(dateValue)");
		return true;
	}

	if (isPastNineToday() && isBeforeToday(dateValue)) {
		// console.log(
		// 	"checkDisabled: isPastNineToday() && isBeforeToday(dateValue)"
		// );
		return true;
	}
	if (isAdmin) {
		if (isPastTenToday() && isTomorrow(dateValue)) {
			// console.log(
			// 	"checkDisabled: isPastTenToday() && isTomorrow(dateValue)"
			// );
			return true;
		}
	} else {
		if (isPastNineToday() && isTomorrow(dateValue)) {
			// console.log(
			// 	"checkDisabled: isPastNineToday() && isTomorrow(dateValue)"
			// );
			return true;
		}
	}

	if (isToday(dateValue)) {
		// console.log("checkDisabled: isToday(dateValue)");
		return true;
	}

	const currentDay = dayjs().startOf("day");
	const isSaturday = currentDay.day() === 6;
	const isSunday = currentDay.day() === 0;
	const nextMonday = currentDay.day(8).startOf("day"); // Set the date to the next Monday
	const isNextMonday = dayjs(dateValue).isSame(nextMonday, "day");

	if (
		(isSaturday && isPastNineToday() && isNextMonday) ||
		(isSunday && dayjs(dateValue).isSame(currentDay.day(1), "day"))
	) {
		// It is next Monday and past 9 AM on Saturday or it is Sunday
		return true;
	}

	if (
		customLastDate &&
		dayjs().isAfter(dayjs(customLastDate).startOf("day").add(9, "hour"))
	) {
		// console.log(
		// 	"checkDisabled: Current date/time is past 9 AM of the custom last date"
		// );
		return true;
	}

	return false;
}
