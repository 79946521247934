import React from "react";
import NameField from "./NameField";
import PhoneField from "./PhoneField";
import EmailField from "./EmailField";
import FormGroup from "../../input/FormGroup";

function ContactInformationForm({onCompleted, disabled = false}) {
	return (
		<FormGroup
			onCompleted={(isCompleted) => onCompleted(isCompleted)}
			names={["name", "phone", "email"]}
		>
			<NameField disabled={disabled} />
			<PhoneField disabled={disabled} />
			<EmailField disabled={disabled} />
		</FormGroup>
	);
}

export default ContactInformationForm;
