import {useEffect, useState, useCallback, useContext} from "react";
import {useParams, useNavigate} from "react-router-dom";

import axios from "axios";
import dayjs from "dayjs";
import {
	Grid,
	Button,
	Radio,
	FormControl,
	RadioGroup,
	FormControlLabel,
	TextField,
	FormHelperText,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useForm, FormProvider, Controller} from "react-hook-form";

import QuantityInput from "../input/QuantityInput";
import SectionBox from "./SectionBox";

import {checkDisabled} from "./utils";
import {useSnackbar} from "../../contexts/SnackbarProvider";
import DeleteConfirmationDialog from "../input/DeleteConfirmationDialog";
import {UserContext} from "../../contexts/UserProvider";

function EditOrder() {
	const {code} = useParams();
	const navigate = useNavigate();
	const {isAuthenticated} = useContext(UserContext);

	const [dishes, setDishes] = useState(null);
	const [sides, setSides] = useState(null);
	const [totalDishes, setTotalDishes] = useState({});
	const [editable, setEditable] = useState(false);
	const [editing, setEditing] = useState(false);
	const [order, setOrder] = useState(null);
	const [showDss, setShowDss] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [formattedDate, setFormattedDate] = useState(null);
	const [deliveryDisabled, setDeliveryDisabled] = useState(false);
	const [restDisabled, setRestDisabled] = useState(false);

	const showSnackbar = useSnackbar();

	const methods = useForm({
		mode: "onBlur",
	});

	const {setValue} = methods;

	const fetchMenus = useCallback((date) => {
		const formattedDate = dayjs(date).format("DD-MM-YYYY");
		setFormattedDate(formattedDate);
		axios
			.get("/api/menus/daily-menu", {
				params: {
					date: formattedDate,
				},
			})
			.then((res) => {
				if (
					!checkDisabled(
						res.data.dishes,
						dayjs(formattedDate.split("-").reverse().join("-")),
						res.data.dateOptions?.orderBefore || null
					)
				) {
					setDishes(res.data.dishes);
				} else {
					setDishes(res.data.dishes);
					setEditable(false);
				}
				setDeliveryDisabled(
					res.data.dateOptions?.deliveryDisabled || false
				);
				setRestDisabled(res.data.dateOptions?.restDisabled || false);
			})
			.catch((err) => {
				console.log(err);
			});
		axios
			.get("/api/menus/side-menu")
			.then((res) => {
				setSides(res.data);
			})
			.catch((err) => {});
	}, []);

	useEffect(() => {
		if (!code) return navigate("/");
		axios
			.get("/api/orders/order", {
				params: {
					code: code,
				},
			})
			.then((res) => {
				if (res.data) {
					setEditable(true);
					setOrder(res.data);
					fetchMenus(res.data.date);
					setShowDss(res.data.pickup === "DSS Pickup");
				} else {
					navigate("/");
				}
			})
			.catch((err) => {
				navigate("/");
			});
	}, [code, navigate, fetchMenus]);

	const handleDishChange = useCallback((id, value) => {
		setTotalDishes((prev) => {
			const newDishes = {...prev};
			newDishes[id] = value;
			// const total = Object.values(newDishes).reduce((a, b) => a + b, 0);

			return newDishes;
		});
	}, []);

	// useEffect(() => {}, [order]);

	const handleRadioChange = useCallback(
		(event) => {
			if (event.target.value === "DSS Pickup") {
				setShowDss(true);
			} else {
				setShowDss(false);
				setValue("dss", "");
			}
		},
		[setValue]
	);

	const onSubmit = useCallback(
		(data) => {
			// if (!editing) return; // Return immediately if not in editing mode
			data.date = order.date;
			axios
				.put(`/api/orders/edit-order/${code}`, data)
				.then((res) => {
					if (res.data) {
						setEditing(false); // Switch out of editing mode once data is updated
						setOrder(res.data);
						showSnackbar(
							"Η παραγγελία ενημερώθηκε επιτυχώς!",
							4000,
							"success"
						);
					} else {
						setOrder(order);
						showSnackbar(
							"Υπήρξε κάποιο σφάλμα στην ενημέρωση της παραγγελίας!",
							4000,
							"error"
						);
					}
				})
				.catch((err) => {
					console.error(err);
					setOrder(order);
					showSnackbar(
						"Υπήρξε κάποιο σφάλμα στην ενημέρωση της παραγγελίας!",
						4000,
						"error"
					);
				});
		},
		[code, order, showSnackbar]
	);

	const handleDelete = () => {
		// Confirm with the user before deleting (this could be your DeleteConfirmationDialog)
		axios
			.delete(`/api/orders/delete-order/${code}`)
			.then((res) => {
				if (res.data) {
					// Redirect or set the state to show the order has been deleted.
					// Here, I'm just assuming you'd navigate to a list of orders.
					navigate("/");

					showSnackbar(
						"Η παραγγελία διαγράφηκε επιτυχώς!",
						4000,
						"success"
					);
				} else {
					showSnackbar(
						"Υπήρξε κάποιο σφάλμα κατά τη διαγραφή της παραγγελίας!",
						4000,
						"error"
					);
				}
			})
			.catch((err) => {
				console.error(err);
				showSnackbar(
					"Υπήρξε κάποιο σφάλμα κατά τη διαγραφή της παραγγελίας!",
					4000,
					"error"
				);
			});
	};

	return (
		<div style={{textAlign: "left"}}>
			<div style={{position: "relative"}}>
				<Button
					onClick={() => navigate("/")}
					startIcon={<ArrowBackIcon />}
					sx={{
						position: "absolute",
						left: 0, // align it to the left of the container
						top: "50%", // vertically center it relative to container
						transform: "translateY(-50%)", // adjust for exact centering
					}}
					size="large"
				>
					Πισω
				</Button>
				<h1 style={{textAlign: "center"}}>Προβολή παραγγελίας</h1>
				<h3 style={{textAlign: "center"}}>
					Ημερομηνία παραγγελίας:{" "}
					{order &&
						dayjs(formattedDate.split("-").reverse().join("-"))
							.locale("el")
							.format("dddd DD/MM")}
				</h3>
			</div>

			{order && (
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(onSubmit)}>
						<Grid
							container
							style={{
								textAlign: "left",
								fontSize: "1.1rem",
							}}
							spacing={2}
						>
							<Grid item xs={12}>
								<SectionBox title="ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ">
									<TextField
										label="Ονοματεπώνυμο"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											sx: {fontSize: "1.4rem"},
										}}
										InputProps={{
											style: {
												marginTop: 20,
											},
										}}
										sx={{marginBottom: 4}}
										fullWidth
										value={order.name}
										disabled
									/>
									<TextField
										label="Τηλέφωνο"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											sx: {fontSize: "1.4rem"},
										}}
										InputProps={{
											style: {
												marginTop: 20,
											},
										}}
										sx={{marginBottom: 4}}
										fullWidth
										value={order.phone}
										disabled
									/>
									<TextField
										label="Email"
										variant="standard"
										InputLabelProps={{
											shrink: true,
											sx: {fontSize: "1.4rem"},
										}}
										InputProps={{
											style: {
												marginTop: 20,
											},
										}}
										sx={{marginBottom: 4}}
										fullWidth
										value={order.email}
										disabled
									/>
								</SectionBox>

								<SectionBox
									title="ΓΕΥΜΑΤΑ"
									style={{justifyContent: "space-between"}}
									id="dishes"
								>
									{dishes &&
										dishes.map((dish) => {
											const orderDish = order.dishes.find(
												(d) => d.dish === dish._id
											);
											return (
												<Controller
													key={dish._id}
													name={`dishes.${dish._id}`}
													control={methods.control}
													defaultValue={
														orderDish?.quantity || 0
													}
													rules={{required: true}}
													render={({field}) => (
														<QuantityInput
															label={dish.name}
															value={field.value}
															onChange={(
																value
															) => {
																field.onChange(
																	value
																);
																handleDishChange(
																	dish._id,
																	value
																);
															}}
															defaultValue={
																field.value
															}
															disabled={!editing}
														/>
													)}
												/>
											);
										})}
								</SectionBox>

								<SectionBox title="ΣΑΛΑΤΕΣ - ΔΙΑΦΟΡΑ">
									{sides &&
										sides.map((side) => {
											const orderSide = order.sides.find(
												(s) => s.side === side._id
											);
											return (
												<Controller
													key={side._id}
													name={`sides.${side._id}`}
													control={methods.control}
													defaultValue={
														orderSide?.quantity || 0
													}
													rules={{required: true}}
													render={({field}) => (
														<QuantityInput
															label={side.name}
															value={field.value}
															onChange={(value) =>
																field.onChange(
																	value
																)
															}
															defaultValue={
																field.value
															}
															disabled={!editing}
														/>
													)}
												/>
											);
										})}
								</SectionBox>
								{order && (
									<SectionBox
										title="ΣΤΟΙΧΕΙΑ ΠΑΡΑΛΑΒΗΣ"
										style={{textAlign: "left"}}
									>
										<FormControl>
											<Controller
												name="paralavi"
												control={methods.control}
												defaultValue={order.pickup}
												rules={{required: true}}
												render={({field}) => (
													<RadioGroup
														{...field}
														onChange={(e) => {
															field.onChange(e);
															handleRadioChange(
																e
															);
														}}
													>
														{!restDisabled && (
															<FormControlLabel
																value="LAF Xanthis"
																control={
																	<Radio />
																}
																label="ΛΑΦ Ξάνθης Εστιατόριο"
																disabled={
																	!editing
																}
															/>
														)}
														<FormControlLabel
															value="LAF Xanthis Pickup"
															control={<Radio />}
															label="ΛΑΦ Ξάνθης Πακέτο"
															disabled={!editing}
														/>
														{/* If it's saturday or sunday, don't display these */}
														{/* dateOption.disableDelivery is not true */}
														{dayjs(
															order.date
														).day() !== 6 &&
															dayjs(
																order.date
															).day() !== 0 &&
															!deliveryDisabled && (
																<>
																	<FormControlLabel
																		value="Anapsiktirio Pickup"
																		control={
																			<Radio />
																		}
																		label="Αναψυκτήριο Πακέτο"
																		disabled={
																			!editing
																		}
																	/>
																	<FormControlLabel
																		value="DSS Pickup"
																		control={
																			<Radio />
																		}
																		label="Δ'ΣΣ Πακέτο (Στρατόπεδο Αποστολίδη)"
																		disabled={
																			!editing
																		}
																	/>
																</>
															)}
													</RadioGroup>
												)}
											/>

											<Controller
												name="dss"
												control={methods.control}
												defaultValue={order.dss}
												rules={{required: showDss}}
												render={({field}) => (
													<TextField
														{...field}
														label="Σημείο Παραλαβής"
														variant="standard"
														InputLabelProps={{
															shrink: true,
															sx: {
																fontSize:
																	"1.4rem",
															},
														}}
														InputProps={{
															style: {
																marginTop: 20,
															},
														}}
														sx={{
															marginBottom: 1,
															marginTop: 1,
															display:
																showDss === true
																	? "block"
																	: "none",
														}}
														fullWidth
														error={
															!!methods.formState
																.errors.dss
														}
														disabled={!editing}
													/>
												)}
											/>

											<FormHelperText
												sx={{
													display:
														showDss === true
															? "block"
															: "none",
												}}
											>
												Συμπληρώστε το ακριβές σημείο
												στο στρατόπεδο Αποστολίδη, στο
												οποίο θέλετε να γίνει η παράδοση
												του πακέτου
											</FormHelperText>
										</FormControl>
									</SectionBox>
								)}
								{order && (
									<SectionBox
										title="ΣΗΜΕΙΩΣΕΙΣ"
										style={{textAlign: "left"}}
									>
										<Controller
											name="notes"
											control={methods.control}
											defaultValue={order.notes}
											render={({field}) => (
												<TextField
													{...field}
													label="Σημειώσεις"
													variant="standard"
													multiline
													rows={3}
													InputLabelProps={{
														shrink: true,
														sx: {
															fontSize: "1.4rem",
														},
													}}
													InputProps={{
														style: {
															marginTop: 20,
														},
													}}
													fullWidth
													disabled={!editing}
												/>
											)}
										/>
									</SectionBox>
								)}
							</Grid>
							{editable && (
								<Grid item sx={{textAlign: "center"}} xs={12}>
									<Button
										variant="contained"
										color={editing ? "success" : "primary"}
										sx={{marginBottom: 2, marginX: 3}}
										type={editing ? "button" : "submit"}
										size="large"
										onClick={() => setEditing(!editing)}
										// endIcon={<ShoppingCartCheckoutIcon />}
									>
										{editing ? "Αποθήκευση" : "Τροποποίηση"}
									</Button>
									<Button
										variant="contained"
										color="error"
										sx={{marginBottom: 2, marginX: 3}}
										type="button"
										size="large"
										onClick={() =>
											setOpenDeleteDialog(true)
										}
										// endIcon={<ShoppingCartCheckoutIcon />}
									>
										Ακύρωση
									</Button>
									<DeleteConfirmationDialog
										open={openDeleteDialog}
										onClose={() =>
											setOpenDeleteDialog(false)
										}
										onConfirm={handleDelete}
									/>
								</Grid>
							)}
						</Grid>
					</form>
				</FormProvider>
			)}
		</div>
	);
}

export default EditOrder;
