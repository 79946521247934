import {useState} from "react";
import {Routes, Route, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
// @mui
import {styled} from "@mui/material/styles";
//
import Header from "./components/Header";
import Nav from "./components/Nav";
import {Container} from "@mui/material";

import Dashboard from "./Dashboard";
import Menu from "./Menu";
import Orders from "./Orders";
import Sides from "./Sides";
import MenuAdd from "./components/menu/MenuAdd";
import OrderIndex from "./OrderIndex";
import OrderDate from "./OrderDate";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
	display: "flex",
	minHeight: "100%",
	overflow: "hidden",
});

const Main = styled("div")(({theme}) => ({
	flexGrow: 1,
	overflow: "auto",
	minHeight: "100%",
	paddingTop: APP_BAR_MOBILE + 24,
	paddingBottom: theme.spacing(10),
	[theme.breakpoints.up("lg")]: {
		paddingTop: APP_BAR_DESKTOP + 24,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	textAlign: "left",
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
	const [open, setOpen] = useState(false);
	const location = useLocation();

	return (
		<StyledRoot>
			{!location.pathname.startsWith(
				"/diaxirisi/paraggelies/prosthiki"
			) && <Header onOpenNav={() => setOpen(true)} />}

			<Helmet>
				<title>ΛΑΦ Ξάνθης - Διαχείριση</title>
			</Helmet>
			{!location.pathname.startsWith(
				"/diaxirisi/paraggelies/prosthiki"
			) && <Nav openNav={open} onCloseNav={() => setOpen(false)} />}

			<Main>
				<Container maxWidth="xl">
					<Routes>
						<Route index element={<Dashboard />} />
						<Route path="/menou" element={<Menu />} />
						<Route
							path="/menou/epeksergasia"
							element={<MenuAdd />}
						/>
						<Route path="/paraggelies" element={<Orders />} />
						<Route
							path="/paraggelies/prosthiki"
							element={<OrderIndex />}
						/>
						<Route
							path="/paraggelies/prosthiki/:date"
							element={<OrderDate />}
						/>
						<Route path="/diafora" element={<Sides />} />
						<Route path="/*" element={<h1>Δε βρέθηκε</h1>} />
					</Routes>
				</Container>
			</Main>
		</StyledRoot>
	);
}
