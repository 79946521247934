import React from "react";
import {Link} from "react-router-dom";
import AbcIcon from "@mui/icons-material/Abc";

function Home() {
	return (
		<div>
			<Link to="/order">Order</Link>
			<AbcIcon />
		</div>
	);
}

export default Home;
