import {useEffect, useState} from "react";
import axios from "axios";
// @mui
import {
	Card,
	Stack,
	Button,
	Container,
	Typography,
	TableContainer,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
// components
import Scrollbar from "./components/scrollbar";
// sections
import {MenuListToolbar} from "./components/menu";
import dayjs from "dayjs";
import {MenuByDish, MenuByDate} from "./components/menu";
import {useSnackbar} from "../../contexts/SnackbarProvider";
import {Link} from "react-router-dom";

// ----------------------------------------------------------------------

function Menu() {
	const [selected, setSelected] = useState([]);

	const [filterName, setFilterName] = useState("");

	const [menu, setMenu] = useState([]);

	const [byDate, setByDate] = useState(false);

	const showSnackbar = useSnackbar();

	function parseDishes(data) {
		var parsedDishes = {};
		const typeOrder = {
			main: 1,
			pescatarian: 2,
			dessert: 3,
		};

		data.reduce((acc, dish) => {
			const date = dayjs(dish.date).format("YYYY-MM-DD");
			if (acc[date]) {
				acc[date].push(dish);
			} else {
				acc[date] = [dish];
			}
			return acc;
		}, parsedDishes);

		// Now, sort the dishes for each date separately
		for (let date in parsedDishes) {
			parsedDishes[date].sort((a, b) => {
				return typeOrder[a.type] - typeOrder[b.type];
			});
		}

		return parsedDishes;
	}

	const handleByDateChange = (event) => {
		const isChecked = event.target.checked;
		setByDate(isChecked);
	};

	const handleFilterByName = (event) => {
		setFilterName(event.target.value);
	};

	const refreshMenu = () => {
		axios
			.get("/api/menus/full-menu")
			.then((res) => {
				const parsedData = res.data.dishes;
				setMenu(parsedData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleMultipleDelete = () => {
		if (selected.length === 0) return;
		if (!byDate) {
			axios
				.delete("/api/dishes/delete-dishes", {
					data: {
						dishes: selected,
					},
				})
				.then((res) => {
					if (res.status === 200 && res.data.success) {
						showSnackbar(
							"Τα πιάτα διαγράφηκαν επιτυχώς",
							4000,
							"success"
						);
						refreshMenu();
					} else {
						showSnackbar("Κάτι πήγε στραβά", 4000, "error");
						console.log("Something went wrong");
					}
				})
				.catch((err) => {
					showSnackbar("Κάτι πήγε στραβά", 4000, "error");
					console.log(err);
				});
		} else {
			axios
				.delete("/api/dishes/delete-dates", {
					data: {dates: selected},
				})
				.then((res) => {
					if (res.status === 200 && res.data.success) {
						showSnackbar(
							"Τα πιάτα διαγράφηκαν επιτυχώς",
							4000,
							"success"
						);
						refreshMenu();
					} else {
						showSnackbar("Κάτι πήγε στραβά", 4000, "error");
						console.log("Something went wrong");
					}
				})
				.catch((err) => {
					showSnackbar("Κάτι πήγε στραβά", 4000, "error");
					console.log(err);
				});
		}
		setSelected([]);
	};

	useEffect(() => {
		refreshMenu();
	}, []);

	return (
		<>
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Μενού
					</Typography>
					<Link
						to="/diaxirisi/menou/epeksergasia"
						style={{textDecoration: "none"}}
					>
						<Button
							variant="contained"
							startIcon={<SettingsIcon />}
						>
							Ενεργειες
						</Button>
					</Link>
				</Stack>

				<Card>
					<MenuListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
						switchState={byDate}
						handleSwitchState={handleByDateChange}
						handleMultipleDelete={handleMultipleDelete}
					/>

					<Scrollbar>
						<TableContainer sx={{minWidth: 800}}>
							{byDate ? (
								<MenuByDate
									menu={parseDishes(menu)}
									filterName={filterName}
									selected={selected}
									setSelected={setSelected}
									refreshMenu={refreshMenu}
								/>
							) : (
								<MenuByDish
									menu={menu}
									filterName={filterName}
									selected={selected}
									setSelected={setSelected}
									refreshMenu={refreshMenu}
								/>
							)}
						</TableContainer>
					</Scrollbar>
				</Card>
			</Container>
		</>
	);
}

export default Menu;
