import {useState, useContext} from "react";
import Header from "../Header";
import SectionBox from "../order/SectionBox";
import {useForm, Controller} from "react-hook-form";
import {
	Button,
	TextField,
	Container,
	IconButton,
	InputAdornment,
} from "@mui/material";

import {Visibility, VisibilityOff} from "@mui/icons-material";

import {UserContext} from "../../contexts/UserProvider";

function Login() {
	const {
		handleSubmit,
		control,
		formState: {errors},
	} = useForm();

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const {login, error: loginError} = useContext(UserContext);

	const [errorMessage, setErrorMessage] = useState(null);

	const onSubmit = async (data) => {
		try {
			await login(data.username, data.password);
			if (loginError) {
				setErrorMessage(loginError);
			}
		} catch (error) {
			// Since you are already setting the error in the `login` function of UserProvider,
			// you can directly use it here
			setErrorMessage(error.message);
		}
	};

	return (
		<div>
			<Header />
			<SectionBox title="Σύνδεση">
				<Container maxWidth="xs">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							name="username"
							control={control}
							defaultValue=""
							rules={{
								required: "Το όνομα χρήστη είναι υποχρεωτικό",
							}}
							render={({field}) => (
								<TextField
									{...field}
									onChange={(e) => {
										field.onChange(e);
										setErrorMessage(null);
									}}
									label="Όνομα Χρήστη"
									variant="outlined"
									fullWidth
									margin="normal"
									error={!!errors.username}
									helperText={errors?.username?.message}
								/>
							)}
						/>
						<Controller
							name="password"
							control={control}
							defaultValue=""
							rules={{required: "Ο κωδικός είναι υποχρεωτικός"}}
							render={({field}) => (
								<TextField
									{...field}
									onChange={(e) => {
										field.onChange(e);
										setErrorMessage(null);
									}}
									type={showPassword ? "text" : "password"}
									label="Κωδικός"
									variant="outlined"
									fullWidth
									margin="normal"
									error={!!errors.password}
									helperText={errors?.password?.message}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={
														handleClickShowPassword
													}
													onMouseDown={
														handleMouseDownPassword
													}
													edge="end"
												>
													{showPassword ? (
														<VisibilityOff />
													) : (
														<Visibility />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							)}
						/>
						{errorMessage && (
							<div style={{color: "red", margin: "10px 0"}}>
								{errorMessage}
							</div>
						)}
						<Button
							variant="contained"
							color="primary"
							type="submit"
						>
							Συνδεση
						</Button>
					</form>
				</Container>
			</SectionBox>
		</div>
	);
}

export default Login;
