import {useState} from "react";
import {
	Table,
	TablePagination,
	Checkbox,
	IconButton,
	TableRow,
	TableCell,
	Typography,
	MenuItem,
	Popover,
	TableBody,
	Paper,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {MenuListHead} from ".";
import dayjs from "dayjs";
import DeleteConfirmationDialog from "../../../input/DeleteConfirmationDialog";

// usenavigate
import {useNavigate} from "react-router-dom";

const TABLE_HEAD = [
	{id: "date", label: "Ημερομηνία", alignRight: false},
	{id: "dishes", label: "Πιάτα", alignRight: false},
	{id: ""},
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function MenuByDate({menu, filterName, selected, setSelected}) {
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("date");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [open, setOpen] = useState(null);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [targetDate, setTargetDate] = useState(null);

	const navigate = useNavigate();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = Object.keys(menu);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = (event) => {
		event.stopPropagation();
		setOpen(null);
	};

	const handleOpenDeleteDialog = (date) => {
		setTargetDate(date);
		setOpenDeleteDialog(true);
	};

	const handleDelete = () => {
		setTargetDate(null);
		setOpenDeleteDialog(false);
	};

	const emptyRows =
		page > 0
			? Math.max(0, (1 + page) * rowsPerPage - Object.keys(menu).length)
			: 0;
	const filteredMenu = Object.keys(menu)
		.map((date) => ({date, dishes: menu[date]}))
		.sort(getComparator(order, orderBy));

	const isNotFound = !filteredMenu.length && !!filterName;

	return (
		<>
			<Table>
				<MenuListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={Object.keys(menu).length}
					numSelected={selected.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{filteredMenu
						.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)
						.map((row) => {
							const {date, dishes} = row;
							const selectedDate = selected.indexOf(date) !== -1;
							return (
								<TableRow
									hover
									key={date}
									tabIndex={-1}
									role="checkbox"
								>
									<TableCell padding="checkbox">
										<Checkbox
											checked={selectedDate}
											onChange={(event) =>
												handleClick(event, date)
											}
										/>
									</TableCell>
									<TableCell
										component="th"
										scope="row"
										padding="none"
									>
										<Typography variant="subtitle2" noWrap>
											{dayjs(date).format("DD/MM/YYYY") ||
												"-"}
										</Typography>
									</TableCell>

									<TableCell align="left">
										{dishes.length}
									</TableCell>
									{/* <TableCell align="right">
										<IconButton
											size="large"
											color="inherit"
											onClick={handleOpenMenu}
										>
											<MoreVertIcon />
										</IconButton>
									</TableCell> */}
									<TableCell align="right">
										<IconButton
											// size="large"
											sx={{color: "info.main"}}
											onClick={() =>
												navigate(
													`/diaxirisi/menou/epeksergasia?date=${date
														.split("-")
														.reverse()
														.join("-")}`
												)
											}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											// size="large"
											sx={{color: "error.main"}}
											onClick={() =>
												handleOpenDeleteDialog(date)
											}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
				{emptyRows > 0 && (
					<TableRow style={{height: 53 * emptyRows}}>
						<TableCell colSpan={6} />
					</TableRow>
				)}

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{py: 3}}>
								<Paper
									sx={{
										textAlign: "center",
										boxShadow: "none",
									}}
								>
									<Typography variant="h6" paragraph>
										Δεν βρέθηκαν ημερομηνίες
									</Typography>

									<Typography variant="body2">
										Δεν βρέθηκαν ημερομηνίες για &nbsp;
										<strong>
											&quot;{filterName}
											&quot;
										</strong>
										.
										<br /> Ελέγξτε την ορθότητα της
										αναζήτησης ή δοκιμάστε να
										χρησιμοποιήσετε έναν διαφορετικό όρο
										αναζήτησης.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>

			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={Object.keys(menu).length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="Ημερομηνίες ανά σελίδα"
				labelDisplayedRows={({from, to, count}) =>
					`${from}-${to} από ${count}`
				}
			/>
			<Popover
				open={Boolean(open)}
				// hideBackdrop
				// disableScrollLock
				// disableEnforceFocus
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{vertical: "top", horizontal: "left"}}
				transformOrigin={{vertical: "top", horizontal: "right"}}
				slotProps={{
					paper: {
						sx: {
							p: 1,
							width: 140,
							"& .MuiMenuItem-root": {
								px: 1,
								typography: "body2",
								borderRadius: 0.75,
							},
						},
					},
				}}
			>
				<MenuItem>
					<EditIcon sx={{mr: 1}} />
					Επεξεργασία
				</MenuItem>

				<MenuItem sx={{color: "error.main"}}>
					<DeleteIcon sx={{mr: 1}} />
					Διαγραφή
				</MenuItem>
			</Popover>
			<DeleteConfirmationDialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onConfirm={handleDelete}
				title="Διαγραφή πιάτων ημερομηνίας"
				description="Είστε σίγουρος για την διαγραφή των πιάτων της ημερομηνίας;"
			/>
		</>
	);
}

export default MenuByDate;
