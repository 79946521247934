import React from "react";
import {Card, Typography} from "@mui/material";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	ResponsiveContainer,
	Tooltip,
	Cell,
} from "recharts";

function TotalRevenueSidesChart({data}) {
	return (
		<Card sx={{height: 350}}>
			<Typography variant="h5" sx={{padding: 2}} textAlign={"center"}>
				Έσοδα Ανά Συνοδευτικό
			</Typography>
			<ResponsiveContainer width="100%" height="80%">
				<BarChart
					data={data}
					margin={{
						top: 15,
						right: 20,
						left: 15,
						bottom: 15,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Bar
						dataKey="amount"
						fill="#8884d8"
						label={{position: "top"}}
					/>
				</BarChart>
			</ResponsiveContainer>
		</Card>
	);
}

export default TotalRevenueSidesChart;
