import React from "react";
import PropTypes from "prop-types";
import {styled} from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {stepConnectorClasses} from "@mui/material/StepConnector";
import StepContent, {stepContentClasses} from "@mui/material/StepContent";
import StepLabel, {stepLabelClasses} from "@mui/material/StepLabel";

const QontoConnector = styled(StepConnector)(({theme}) => ({
	// [`&.${stepConnectorClasses.alternativeLabel}`]: {
	// 	top: 10,
	// 	left: "calc(-50% + 16px)",
	// 	right: "calc(50% + 16px)",
	// },
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#515FC2",
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		color: "#515FC2",

		[`& .${stepConnectorClasses.line}`]: {
			borderColor: "#515FC2",
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor:
			theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
		borderLeftWidth: 3,
		borderRadius: 1,
		height: "100%",
		display: "none",
	},
}));

const QontoStepContent = styled(StepContent)(({theme}) => ({
	[`&.${stepContentClasses.root}`]: {
		borderLeftWidth: 3,
		borderRadius: 1,
		borderColor: "#515FC2",
	},
}));

const QontoStepLabel = styled(StepLabel)(({theme}) => ({
	[`& .${stepLabelClasses.completed}`]: {
		color: "#515FC2",
		fontWeight: "bold",
	},
}));

const QontoStepIconRoot = styled("div")(({theme, ownerState}) => ({
	color: "#515FC2",
	display: "flex",
	height: 18,
	width: 18,
	alignItems: "center",
	justifyContent: "center",
	marginLeft: 4,
	...(ownerState.active && {
		color: "#515FC2",
	}),
	"& .QontoStepIcon-completedIcon": {
		color: "#515FC2",
		zIndex: 1,
		fontSize: 18,
	},
	"& .QontoStepIcon-circle": {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
}));

function QontoStepIcon(props) {
	const {active, completed, className} = props;

	return (
		<QontoStepIconRoot ownerState={{active}} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

export {QontoConnector, QontoStepIcon, QontoStepContent, QontoStepLabel};
