import React, {useState, useEffect} from "react";
import {
	TextField,
	Checkbox,
	FormControlLabel,
	RadioGroup,
	Radio,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from "@mui/material";

const DynamicDialog = ({
	open,
	handleClose,
	formFields,
	onSubmit,
	initialValues,
	title,
	description,
}) => {
	const [formData, setFormData] = useState(initialValues || {});

	const handleInputChange = (event) => {
		const {name, value, type, checked} = event.target;
		setFormData((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const generateInitialFormData = (fields) => {
		const initialData = {};
		fields.forEach((field) => {
			if ("value" in field) {
				initialData[field.name] = field.value;
			} else {
				initialData[field.name] =
					field.type === "checkbox" ? false : "";
			}
		});
		return initialData;
	};

	useEffect(() => {
		setFormData(initialValues || generateInitialFormData(formFields));
	}, [initialValues, formFields]);

	return (
		<Dialog
			open={open}
			onClose={() => {
				setFormData({});
				handleClose();
			}}
		>
			{title && <DialogTitle>{title}</DialogTitle>}
			<DialogContent>
				{description && (
					<DialogContentText>{description}</DialogContentText>
				)}
				{formFields.map((field) => {
					switch (field.type) {
						case "text":
							return (
								<TextField
									key={field.name}
									name={field.name}
									label={field.label}
									value={formData[field.name] || ""}
									onChange={handleInputChange}
									fullWidth
									margin="dense"
									required
								/>
							);
						case "checkbox":
							return (
								<FormControlLabel
									key={field.name}
									control={
										<Checkbox
											name={field.name}
											checked={
												formData[field.name] || false
											}
											onChange={handleInputChange}
										/>
									}
									label={field.label}
								/>
							);
						case "radio":
							return (
								<RadioGroup
									key={field.name}
									name={field.name}
									value={formData[field.name] || ""}
									onChange={handleInputChange}
								>
									{field.options.map((option) => (
										<FormControlLabel
											key={option.value}
											value={option.value}
											control={<Radio />}
											label={option.label}
										/>
									))}
								</RadioGroup>
							);
						case "number":
							return (
								<TextField
									key={field.name}
									name={field.name}
									label={field.label}
									value={formData[field.name] || ""}
									onChange={handleInputChange}
									fullWidth
									margin="dense"
									type="number"
								/>
							);
						default:
							return null;
					}
				})}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Ακυρωση
				</Button>
				<Button
					onClick={() => onSubmit(formData)}
					variant="contained"
					color="primary"
				>
					Αποθηκευση
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DynamicDialog;
