import React, {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {useRef} from "react";

function FormGroup({children, onCompleted, names}) {
	const {
		watch,
		formState: {errors},
	} = useFormContext();
	const isCompletedRef = useRef(false);
	const values = watch(names);

	useEffect(() => {
		const isCompleted =
			Object.values(values).every((value) => value) &&
			Object.keys(errors).length === 0;
		if (isCompleted !== isCompletedRef.current) {
			isCompletedRef.current = isCompleted;
			onCompleted(isCompleted);
		}
	}, [errors, onCompleted, values]);

	return <div>{children}</div>;
}

export default FormGroup;
