import React from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import SectionBox from "./SectionBox";

import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Success() {
	const {state} = useLocation();
	const navigate = useNavigate();

	// if state is null
	if (state === null) {
		navigate("/");
	}

	// get code from state
	const code = state?.code;

	if (!code) {
		navigate("/");
	}

	return (
		<div style={{textAlign: "left"}}>
			<Button
				onClick={() =>
					state.isAdmin
						? navigate("/diaxirisi/paraggelies/prosthiki/")
						: navigate("/")
				}
				startIcon={<ArrowBackIcon />}
			>
				Πισω
			</Button>
			<SectionBox>
				<h1>Η παραγγελία σας ολοκληρώθηκε με επιτυχία!</h1>
				<p>
					Σας έχουμε στείλει ένα email επιβεβαίωσης της παραγγελίας
					σας. Εάν δεν το βρίσκετε στα εισερχόμενα, ελέγξτε τον φάκελο
					των ανεπιθύμητων. <br />
					Μπορείτε να επεξεργαστείτε την παραγγελία σας πατώντας{" "}
					<Link to={`/view/${code}`}>εδώ</Link>.
				</p>
			</SectionBox>
		</div>
	);
}

export default Success;
