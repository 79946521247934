import React from "react";
import {Routes, Route} from "react-router-dom";
import Header from "../Header";
import OrderIndex from "./OrderIndex";
import OrderDate from "./OrderDate";
import Success from "./Success";
import EditOrder from "./EditOrder";

function Order() {
	return (
		<div>
			<Header />
			<Routes>
				<Route index element={<OrderIndex />} />
				<Route path="/success" element={<Success />} />
				<Route path="/view/:code" element={<EditOrder />} />
				<Route path=":date" element={<OrderDate />} />
				<Route path="*" element={<h1>4042</h1>} />
			</Routes>
		</div>
	);
}

export default Order;
