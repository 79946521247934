import React, {useEffect, useState} from "react";
import {
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Typography,
} from "@mui/material";

function shortenString(string, maxLength = 14) {
	if (string.length <= maxLength) {
		return string;
	}
	return string.substring(0, maxLength) + "...";
}

function processData(printData, fullMenu) {
	// Initialize data structures
	const dishTotals = {};
	const sideTotals = {};
	const dishGrandTotals = {quantity: 0, price: 0.0};
	const sideGrandTotals = {quantity: 0, price: 0.0};
	const ordersByCategory = [];

	// Categories
	const categories = ["Εστιατόριο", "Εστιατόριο Π", "Α & Δ'ΣΣ"];

	// Initialize the totals structure
	categories.forEach((category) => {
		dishTotals[category] = {};
		sideTotals[category] = {};
		ordersByCategory[category] = 0;
		printData.dishes.forEach((dish) => {
			dishTotals[category][dish._id] = {quantity: 0, price: 0.0};
		});
		fullMenu.sides.forEach((side) => {
			sideTotals[category][side._id] = {quantity: 0, price: 0.0};
		});
	});

	// Process each order to compute totals
	printData.orders.forEach((order) => {
		// Determine category based on pickup
		let category = "";
		if (order.pickup.includes("LAF Xanthis Pickup")) {
			category = "Εστιατόριο Π";
		} else if (
			order.pickup.includes("DSS Pickup") ||
			order.pickup.includes("Anapsiktirio Pickup")
		) {
			category = "Α & Δ'ΣΣ";
		} else {
			category = "Εστιατόριο";
		}

		ordersByCategory[category] += 1;

		const priceType =
			order.pickup === "LAF Xanthis"
				? "restaurant_price"
				: "package_price";
		// Process dishes
		order.dishes.forEach((dishOrder) => {
			const dishId = dishOrder.dish;
			const dish = fullMenu.dishes.find((dish) => dish._id === dishId);
			if (dish) {
				const dishPrice = dish[priceType];
				dishTotals[category][dishId].quantity += dishOrder.quantity;
				dishTotals[category][dishId].price +=
					dishOrder.quantity * dishPrice;
				dishGrandTotals.quantity += dishOrder.quantity;
				dishGrandTotals.price += dishOrder.quantity * dishPrice;
			}
		});

		// Process sides
		order.sides.forEach((sideOrder) => {
			const sideId = sideOrder.side;
			const side = fullMenu.sides.find((side) => side._id === sideId);
			if (side) {
				const sidePrice = side[priceType];
				sideTotals[category][sideId].quantity += sideOrder.quantity;
				sideTotals[category][sideId].price +=
					sideOrder.quantity * sidePrice;
				sideGrandTotals.quantity += sideOrder.quantity;
				sideGrandTotals.price += sideOrder.quantity * sidePrice;
			}
		});
	});

	return {
		dishTotals,
		sideTotals,
		dishGrandTotals,
		sideGrandTotals,
		ordersByCategory,
	};
}

function TotalsTable({printData, fullMenu}) {
	const [processedData, setProcessedData] = useState({
		dishTotals: {},
		sideTotals: {},
		dishGrandTotals: {quantity: 0, price: 0.0},
		sideGrandTotals: {quantity: 0, price: 0.0},
		ordersByCategory: [],
	});

	useEffect(() => {
		const data = processData(printData, fullMenu);
		setProcessedData(data);
	}, [printData, fullMenu]);

	return (
		<div id="totals-table" style={{marginTop: 24, marginBottom: 16}}>
			<Typography variant="h4" component="div" sx={{textAlign: "center"}}>
				Σύνολα
			</Typography>

			<TableContainer
				sx={{border: "1px solid black", width: "99%", marginX: "auto"}}
			>
				{/* Dishes Table */}
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell rowSpan={2} sx={{color: "black"}}>
								Συσσιτίου
							</TableCell>
							{Object.keys(processedData.dishTotals).map(
								(category) => (
									<TableCell colSpan={2} align="center">
										{category}
									</TableCell>
								)
							)}
							<TableCell
								colSpan={2}
								align="center"
								sx={{color: "black"}}
							>
								Σύνολο
							</TableCell>
						</TableRow>
						<TableRow>
							{Object.keys(processedData.dishTotals).map(() => (
								<>
									<TableCell align="center">#</TableCell>
									<TableCell align="center">€</TableCell>
								</>
							))}
							<TableCell align="center" sx={{color: "black"}}>
								#
							</TableCell>
							<TableCell align="center" sx={{color: "black"}}>
								€
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{printData.dishes.map((dish) => (
							<TableRow key={dish._id}>
								<TableCell>
									{shortenString(dish.name)}
								</TableCell>
								{Object.keys(processedData.dishTotals).map(
									(category) => (
										<>
											<TableCell align="center">
												{
													processedData.dishTotals[
														category
													][dish._id].quantity
												}
											</TableCell>
											<TableCell align="center">
												{processedData.dishTotals[
													category
												][dish._id].price.toFixed(2) +
													"€"}
											</TableCell>
										</>
									)
								)}
								<TableCell
									align="center"
									sx={{fontWeight: "bold"}}
								>
									{Object.keys(
										processedData.dishTotals
									).reduce(
										(sum, category) =>
											sum +
											processedData.dishTotals[category][
												dish._id
											].quantity,
										0
									)}
								</TableCell>
								<TableCell
									align="center"
									sx={{fontWeight: "bold"}}
								>
									{Object.keys(processedData.dishTotals)
										.reduce(
											(sum, category) =>
												sum +
												processedData.dishTotals[
													category
												][dish._id].price,
											0
										)
										.toFixed(2) + "€"}
								</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell
								sx={{
									fontWeight: "bold",
								}}
							>
								Σύνολο
							</TableCell>
							{Object.keys(processedData.dishTotals).map(
								(category) => (
									<>
										<TableCell
											align="center"
											sx={{
												fontWeight: "bold",
											}}
										>
											{printData.dishes.reduce(
												(sum, dish) =>
													sum +
													processedData.dishTotals[
														category
													][dish._id].quantity,
												0
											)}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												fontWeight: "bold",
											}}
										>
											{printData.dishes
												.reduce(
													(sum, dish) =>
														sum +
														processedData
															.dishTotals[
															category
														][dish._id].price,
													0
												)
												.toFixed(2) + "€"}
										</TableCell>
									</>
								)
							)}
							<TableCell align="center" sx={{fontWeight: "bold"}}>
								{processedData.dishGrandTotals.quantity}
							</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>
								{processedData.dishGrandTotals.price.toFixed(
									2
								) + "€"}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				{/* Sides Table */}
				<Table sx={{borderTop: "1px solid black"}} size="small">
					<TableHead>
						<TableRow>
							<TableCell rowSpan={2} sx={{color: "black"}}>
								Της Ώρας
							</TableCell>
							{Object.keys(processedData.sideTotals).map(
								(category) => (
									<TableCell colSpan={2} align="center">
										{category}
									</TableCell>
								)
							)}
							<TableCell
								colSpan={2}
								align="center"
								sx={{color: "black"}}
							>
								Σύνολο
							</TableCell>
						</TableRow>
						<TableRow>
							{Object.keys(processedData.sideTotals).map(() => (
								<>
									<TableCell align="center">#</TableCell>
									<TableCell align="center">€</TableCell>
								</>
							))}
							<TableCell align="center" sx={{color: "black"}}>
								#
							</TableCell>
							<TableCell align="center" sx={{color: "black"}}>
								€
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fullMenu.sides.map((side) => (
							<TableRow key={side._id}>
								<TableCell>
									{shortenString(side.name)}
								</TableCell>
								{Object.keys(processedData.sideTotals).map(
									(category) => (
										<>
											<TableCell align="center">
												{
													processedData.sideTotals[
														category
													][side._id].quantity
												}
											</TableCell>
											<TableCell align="center">
												{processedData.sideTotals[
													category
												][side._id].price.toFixed(2) +
													"€"}
											</TableCell>
										</>
									)
								)}
								<TableCell
									align="center"
									sx={{fontWeight: "bold"}}
								>
									{Object.keys(
										processedData.sideTotals
									).reduce(
										(sum, category) =>
											sum +
											processedData.sideTotals[category][
												side._id
											].quantity,
										0
									)}
								</TableCell>
								<TableCell
									align="center"
									sx={{fontWeight: "bold"}}
								>
									{Object.keys(processedData.sideTotals)
										.reduce(
											(sum, category) =>
												sum +
												processedData.sideTotals[
													category
												][side._id].price,
											0
										)
										.toFixed(2) + "€"}
								</TableCell>
							</TableRow>
						))}
						<TableRow>
							<TableCell
								sx={{
									fontWeight: "bold",
								}}
							>
								Σύνολο
							</TableCell>
							{Object.keys(processedData.sideTotals).map(
								(category) => (
									<>
										<TableCell
											align="center"
											sx={{
												fontWeight: "bold",
											}}
										>
											{fullMenu.sides.reduce(
												(sum, side) =>
													sum +
													processedData.sideTotals[
														category
													][side._id].quantity,
												0
											)}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												fontWeight: "bold",
											}}
										>
											{fullMenu.sides
												.reduce(
													(sum, side) =>
														sum +
														processedData
															.sideTotals[
															category
														][side._id].price,
													0
												)
												.toFixed(2) + "€"}
										</TableCell>
									</>
								)
							)}
							<TableCell align="center" sx={{fontWeight: "bold"}}>
								{processedData.sideGrandTotals.quantity}
							</TableCell>
							<TableCell align="center" sx={{fontWeight: "bold"}}>
								{processedData.sideGrandTotals.price.toFixed(
									2
								) + "€"}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				<Table sx={{borderTop: "1px solid black"}} size="small">
					<TableHead>
						<TableRow>
							<TableCell sx={{color: "black"}}>
								Παραγγελίες
							</TableCell>
							{Object.keys(processedData.dishTotals).map(
								(category) => (
									<TableCell align="center">
										{category}
									</TableCell>
								)
							)}
							<TableCell align="center" sx={{color: "black"}}>
								Σύνολο
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell sx={{color: "black"}}>Σύνολο</TableCell>
							{Object.keys(processedData.ordersByCategory).map(
								(category) => (
									<TableCell align="center">
										{
											processedData.ordersByCategory[
												category
											]
										}
									</TableCell>
								)
							)}
							<TableCell align="center" sx={{fontWeight: "bold"}}>
								{Object.keys(
									processedData.ordersByCategory
								).reduce(
									(sum, category) =>
										sum +
										processedData.ordersByCategory[
											category
										],
									0
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default TotalsTable;
