import React, {useContext} from "react";
import {Outlet, Navigate} from "react-router-dom";
import {UserContext} from "../contexts/UserProvider";

function PrivateRoute({children}) {
	const {isAuthenticated} = useContext(UserContext);

	// If not authenticated, redirect to the login page
	// useEffect(() => {
	if (!isAuthenticated) {
		return <Navigate to="/sindesi" replace />;
	}
	// }, [isAuthenticated]);

	// If children are passed, render them
	if (children) {
		return children;
	}

	// If authenticated, render the children routes
	return <Outlet />;
}

function PublicRoute({children}) {
	const {isAuthenticated} = useContext(UserContext);

	// If authenticated, redirect to the diaxirisi page
	if (isAuthenticated) {
		return <Navigate to="/diaxirisi" replace />;
	}

	// If children are passed, render them
	if (children) {
		return children;
	}

	// If not authenticated, render the Outlet (for nested routes)
	return <Outlet />;
}

export {PrivateRoute, PublicRoute};
