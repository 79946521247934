import {useEffect, useState} from "react";
import {
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {styled} from "@mui/material/styles";

const StyledTableRow = styled(TableRow)(({theme}) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
}));

function shortenString(string, maxLength = 14) {
	if (string.length <= maxLength) {
		return string;
	}
	return string.substring(0, maxLength) + "...";
}

function ListTable({printData, fullMenu}) {
	const categories = [
		"Εστιατόριο",
		"Εστιατόριο Πακέτο",
		"Αναψυκτήριο Πακέτο",
		"Δ'ΣΣ Πακέτο",
	];

	return (
		<div id="list-table" style={{marginTop: 8}}>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell
								align="center"
								sx={{color: "black", paddingY: 0}}
							>
								{printData.date.format("DD/MM/YYYY")}
							</TableCell>
						</TableRow>
						<TableRow className="rotate-cells">
							<TableCell align="center">Ονοματεπώνυμο</TableCell>
							<TableCell align="center">Τηλέφωνο</TableCell>
							{printData.dishes.map((dish) => (
								<TableCell align="center" key={dish._id}>
									<div className="rotated-content">
										{shortenString(dish.name, 30)}
									</div>
								</TableCell>
							))}
							{fullMenu.sides.map((side) => (
								<TableCell align="center" key={side._id}>
									<div className="rotated-content">
										{shortenString(side.name, 30)}
									</div>
								</TableCell>
							))}
							{categories.map((category) => (
								<TableCell align="center" key={category}>
									<div className="rotated-content">
										{category}
									</div>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>
		</div>
	);
}

export default ListTable;
