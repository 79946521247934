import {React} from "react";
import logo from "../assets/images/laf-logo.png";
import {styled} from "@mui/system";

const Logo = styled("img")({});

function Header() {
	return (
		<div style={{margin: 4}}>
			<Logo
				src={logo}
				alt="logo"
				sx={{
					height: {
						xs: "auto",
						sm: 128,
					},
					width: {
						xs: "100%",
						sm: "auto",
					},
				}}
			/>
		</div>
	);
}

export default Header;
