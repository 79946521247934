import React from "react";
import {useFormContext} from "react-hook-form";
import {TextField} from "@mui/material";

function PhoneField({disabled}) {
	const {
		register,
		formState: {errors},
	} = useFormContext();
	const error = errors.phone;

	return (
		<TextField
			label="Τηλέφωνο"
			variant="standard"
			{...register("phone", {
				required: "Dose til",
				maxLength: 10,
				pattern: /^[0-9]{10}$/,
			})}
			InputLabelProps={{
				shrink: true,
				sx: {fontSize: "1.4rem"},
			}}
			InputProps={{
				style: {
					marginTop: 20,
				},
			}}
			sx={{marginBottom: 4}}
			error={!!error}
			helperText={error && "Δώσε σωστό τηλέφωνο"}
			fullWidth
			required
			disabled={disabled}
			type="tel"
		/>
	);
}

export default PhoneField;
