import React, {useState, useContext} from "react";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {UserContext} from "../../../contexts/UserProvider";

function AccountPopover() {
	const {user, logout} = useContext(UserContext);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		logout();
		handleClose();
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<div
				onClick={handleClick}
				style={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					color: "initial",
				}}
			>
				{user?.username || "Account"}
				<KeyboardArrowDownIcon fontSize="small" />
			</div>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				PaperProps={{
					sx: {
						p: 0,
						mt: 1.5,
						ml: 0.75,
						width: 180,
						"& .MuiMenuItem-root": {
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<Stack spacing={1} direction="column" sx={{p: 1}}>
					<MenuItem
						onClick={handleLogout}
						sx={{":hover": {backgroundColor: "initial"}}}
					>
						Αποσύνδεση
					</MenuItem>
				</Stack>
			</Popover>
		</>
	);
}

export default AccountPopover;
