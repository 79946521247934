import React from "react";
import {Box} from "@mui/material";

function SectionBox(props) {
	return (
		<Box
			sx={{
				px: 4,
				py: 2,
				boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.5);",
				borderRadius: 2,
				marginX: {
					xs: 0,
					sm: 2,
				},
				marginTop: 2,
				marginBottom: 4,
				backgroundColor: "white",
				...props.style, // Make sure this is at the end
			}}
			style={props.style}
		>
			{props.title && (
				<h2
					style={{
						fontSize: "1.6rem",
						fontWeight: "bold",
						margin: 0,
						marginBottom: "1rem",
					}}
				>
					{props.title}
				</h2>
			)}
			{props.children}
		</Box>
	);
}

export default SectionBox;
