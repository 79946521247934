import BarChartIcon from "@mui/icons-material/BarChart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import RiceBowlIcon from "@mui/icons-material/RiceBowl";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

const navConfig = [
	{
		title: "Διαχείριση",
		path: "/diaxirisi",
		icon: <BarChartIcon />,
	},
	{
		title: "Μενού",
		path: "/diaxirisi/menou",
		icon: <RestaurantMenuIcon />,
	},
	{
		title: "Διάφορα",
		path: "/diaxirisi/diafora",
		icon: <RiceBowlIcon />,
	},
	{
		title: "Παραγγελίες",
		path: "/diaxirisi/paraggelies",
		icon: <ShoppingBagIcon />,
	},
	{
		title: "Δημιουργία Παραγγελίας",
		path: "/diaxirisi/paraggelies/prosthiki",
		icon: <AddShoppingCartIcon />,
	},
];

export default navConfig;
