import PropTypes from "prop-types";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
// @mui
import {Box, Drawer, useMediaQuery} from "@mui/material";
// components
import Logo from "./Logo";
import Scrollbar from "./scrollbar";
import NavSection from "./nav-section";
//
import navConfig from "../config";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
	openNav: PropTypes.bool,
	onCloseNav: PropTypes.func,
};

export default function Nav({openNav, onCloseNav}) {
	const {pathname} = useLocation();

	const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("lg"));

	useEffect(() => {
		if (openNav) {
			onCloseNav();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const renderContent = (
		<Scrollbar
			sx={{
				height: 1,
				"& .simplebar-content": {
					height: 1,
					display: "flex",
					flexDirection: "column",
				},
			}}
		>
			<Box sx={{px: 2.5, py: 3, display: "flex", mx: "auto"}}>
				<Logo />
			</Box>

			<NavSection data={navConfig} />
		</Scrollbar>
	);

	return (
		<Box
			component="nav"
			sx={{
				flexShrink: {lg: 0},
				width: {lg: NAV_WIDTH},
			}}
		>
			{isDesktop ? (
				<Drawer
					open
					variant="permanent"
					PaperProps={{
						sx: {
							width: NAV_WIDTH,
							bgcolor: "white",
							borderRightStyle: "dashed",
						},
					}}
				>
					{renderContent}
				</Drawer>
			) : (
				<Drawer
					open={openNav}
					onClose={onCloseNav}
					ModalProps={{
						keepMounted: true,
					}}
					PaperProps={{
						sx: {width: NAV_WIDTH},
					}}
				>
					{renderContent}
				</Drawer>
			)}
		</Box>
	);
}
