import React from "react";
import {useFormContext} from "react-hook-form";
import {TextField} from "@mui/material";

function EmailField({disabled}) {
	const {
		register,
		formState: {errors},
	} = useFormContext();
	const error = errors.email;

	return (
		<TextField
			label="Email"
			variant="standard"
			{...register("email", {
				required: true,
				pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
			})}
			type="email"
			InputLabelProps={{
				shrink: true,
				sx: {fontSize: "1.4rem"},
			}}
			InputProps={{
				style: {
					marginTop: 20,
				},
			}}
			sx={{marginBottom: 1}}
			error={!!error}
			helperText={error && "Δώσε σωστό email"}
			fullWidth
			required
			disabled={disabled}
		/>
	);
}

export default EmailField;
