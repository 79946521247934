import PropTypes from "prop-types";
// @mui
import {styled} from "@mui/material/styles";
import {
	Box,
	Stack,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
} from "@mui/material";
// components
import MenuIcon from "@mui/icons-material/Menu";
//
import AccountPopover from "./AccountPopover";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({theme}) => ({
	backgroundColor: "white",
	boxShadow: "none",
	[theme.breakpoints.up("lg")]: {
		width: `calc(100% - ${NAV_WIDTH + 1}px)`,
	},
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
	minHeight: HEADER_MOBILE,
	[theme.breakpoints.up("lg")]: {
		minHeight: HEADER_DESKTOP,
		padding: theme.spacing(0, 5),
	},
}));

// ----------------------------------------------------------------------

Header.propTypes = {
	onOpenNav: PropTypes.func,
};

export default function Header({onOpenNav}) {
	return (
		<StyledRoot>
			<StyledToolbar>
				<IconButton
					onClick={onOpenNav}
					sx={{
						mr: 1,
						color: "text.primary",
						display: {lg: "none"},
					}}
				>
					<MenuIcon />
				</IconButton>
				<Box
					sx={{
						flexGrow: 1,
					}}
				>
					<Typography
						variant="h6"
						component="div"
						color="text.primary"
					>
						ΛΑΦ Ξάνθης
					</Typography>
					<Typography
						variant="body2"
						component="div"
						color="text.secondary"
					>
						Διαχείρηση
					</Typography>
				</Box>

				<Stack
					direction="row"
					alignItems="center"
					spacing={{
						xs: 0.5,
						sm: 1,
					}}
				>
					<AccountPopover />
				</Stack>
			</StyledToolbar>
		</StyledRoot>
	);
}
