import {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {StaticDatePicker} from "@mui/x-date-pickers/StaticDatePicker";
import {elGR} from "@mui/x-date-pickers/locales";
import "dayjs/locale/el";
import axios from "axios";
import dayjs from "dayjs";
import {Card, CardContent, Typography, Button, Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import * as utils from "../order/utils";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import dishImage from "../../assets/images/dish.png";
import dessertImage from "../../assets/images/dessert.png";
import pescatarianImage from "../../assets/images/pescatarian.png";

const getImage = (type) => {
	switch (type) {
		case "dessert":
			return dessertImage;
		case "pescatarian":
			return pescatarianImage;
		default:
			return dishImage;
	}
};

const MenuItem = (props) => {
	const image = getImage(props.type);
	return (
		<Grid item xs={12} sm={6} md={3} minHeight={"180px"}>
			<Card
				elevation={3}
				sx={{height: "100%", border: "none", boxShadow: "none"}}
			>
				<img
					src={image}
					alt="dish"
					style={{height: "72px", marginTop: 16, marginBottom: 4}}
				/>
				<CardContent>
					<Typography variant="body1" component="div">
						{props.name}
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	);
};

function OrderIndex() {
	const [dateValue, setDateValue] = useState(dayjs());
	const [menuItems, setMenuItems] = useState([]);
	const [dishes, setDishes] = useState({});
	const [orderBefore, setOrderBefore] = useState(null);

	const navigate = useNavigate();
	const isAfterToday = utils.isAfterToday(dateValue);
	const isTomorrow = utils.isTomorrow(dateValue);

	const div = useRef(null);

	useEffect(() => {
		fetchMenu();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (div.current) {
	// 		div.current.scrollIntoView({behavior: "instant", block: "end"});
	// 	}
	// }, [menuItems]);

	function fetchMenu() {
		axios
			.get("/api/menus/full-menu", {
				params: {
					// after this month
					after: dayjs().startOf("month").format("YYYY-MM-DD"),
				},
			})
			.then((res) => {
				const parsedDishes = parseDishes(res.data);
				setDishes(parsedDishes); // Update state here
				changeDay(dateValue);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	function parseDishes(data) {
		var parsedDishes = {};

		data.dishes.reduce((acc, dish) => {
			const date = dayjs(dish.date).format("YYYY-MM-DD");
			if (acc[date]) {
				acc[date].push(dish);
			} else {
				acc[date] = [dish];
			}
			return acc;
		}, parsedDishes);

		data.dateOptions.forEach((dateOption) => {
			const date = dayjs(dateOption.date).format("YYYY-MM-DD");
			if (dateOption.options.orderBefore && parsedDishes[date]) {
				parsedDishes[date].orderBefore = dayjs(
					dateOption.options.orderBefore
				);
			}
		});

		return parsedDishes;
	}

	function changeDay(newValue) {
		setDateValue(newValue);
		const date = newValue.format("YYYY-MM-DD");
		if (dishes && dishes[date]) {
			setMenuItems(dishes[date]);

			if (dishes[date].orderBefore) {
				setOrderBefore(dishes[date].orderBefore);
			} else {
				setOrderBefore(null);
			}
			// div.current.scrollIntoView({behavior: "instant", block: "end"});
		} else {
			setMenuItems([]);
		}
	}

	useEffect(() => {
		changeDay(dateValue);
	}, [dishes, dateValue]);

	function navigateToOrderDate() {
		navigate(
			`/diaxirisi/paraggelies/prosthiki/${dateValue.format("DD-MM-YYYY")}`
		);
	}

	return (
		<div>
			<Button
				onClick={() => navigate("/diaxirisi/paraggelies/")}
				startIcon={<ArrowBackIcon />}
				sx={{
					position: "absolute",
					left: 20, // align it to the left of the container
					top: 50, // vertically center it relative to container
					transform: "translateY(-50%)", // adjust for exact centering
				}}
				size="large"
			>
				Πισω
			</Button>
			<Box
				sx={{
					p: 2,
					boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.5);",
					borderRadius: 2,
					mx: {
						xs: 0,
						sm: 2,
					},
					my: 2,
					backgroundColor: "white",
					textAlign: "center",
					position: "relative",
				}}
			>
				<Typography
					variant="body2"
					textAlign="center"
					color="rgba(0,0,0,0.6)"
				>
					Επιλέξτε μια ημερομηνία και πατήστε <strong>ΕΠΟΜΕΝΟ</strong>{" "}
					για να προχωρήσετε στην παραγγελία σας.
					<br />
					Επιλέγοντας την ημερομηνία, θα δείτε το μενού της ημέρας
					παρακάτω.
				</Typography>
				<LocalizationProvider
					dateAdapter={AdapterDayjs}
					localeText={
						elGR.components.MuiLocalizationProvider.defaultProps
							.localeText
					}
					adapterLocale="el"
				>
					<div style={{maxWidth: "320px", margin: "auto"}}>
						<StaticDatePicker
							// disablePast
							orientation="portrait"
							value={dateValue}
							onChange={(newValue) => changeDay(newValue)}
							slotProps={{
								actionBar: {
									actions: [],
								},
							}}
							onAccept={() => navigateToOrderDate()}
							sx={{
								width: {
									xs: 280,
									sm: 320,
								},
							}}
						/>
					</div>
					<Button
						variant="contained"
						onClick={() => navigateToOrderDate()}
						disabled={utils.checkDisabled(
							menuItems,
							dateValue,
							orderBefore,
							true
						)}
					>
						Επομενο
					</Button>
				</LocalizationProvider>
			</Box>
			<Box
				sx={{
					p: 2,
					boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.5);",
					borderRadius: 2,
					mx: {
						xs: 0,
						sm: 2,
					},
					my: 2,
					backgroundColor: "white",
					textAlign: "center",
				}}
			>
				<div className="daily-menu" ref={div}>
					<h2>
						Μενού Ημέρας της <b>{dateValue.format("DD/MM/YYYY")}</b>
					</h2>
					{menuItems.length > 0 ? (
						<Grid
							container
							spacing={4}
							justifyContent="center"
							alignItems="stretch"
							sx={{gridAutoRows: "1fr"}}
						>
							{menuItems.map((menuItem) => (
								<MenuItem
									key={menuItem._id}
									name={menuItem.name}
									type={menuItem.type}
								/>
							))}
						</Grid>
					) : (
						<p>
							Δεν βρέθηκαν πιάτα ημέρας. Δοκιμάστε ξανά αργότερα.
						</p>
					)}
				</div>
			</Box>
		</div>
	);
}

export default OrderIndex;
