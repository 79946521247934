import React, {
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect,
} from "react";
import {
	TextField,
	RadioGroup,
	Radio,
	FormControlLabel,
	Button,
	IconButton,
	Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as uuid from "uuid";

const DishForm = forwardRef(({dishes: initialDishes}, ref) => {
	const [dishes, setDishes] = useState(
		initialDishes || [{_id: uuid.v4(), name: "", type: ""}]
	);

	useImperativeHandle(ref, () => ({
		getDishes: () => dishes,
	}));

	useEffect(() => {
		setDishes(initialDishes || [{_id: uuid.v4(), name: "", type: ""}]);
	}, [initialDishes]);

	const handleDishChange = (dishId, field, value) => {
		const newDishes = dishes.map((dish) =>
			dish._id === dishId ? {...dish, [field]: value} : dish
		);
		setDishes(newDishes);
	};

	const handleAddDish = () => {
		setDishes([...dishes, {_id: uuid.v4(), name: "", type: ""}]);
	};

	const handleRemoveDish = (dishId) => {
		const newDishes = dishes.filter((dish) => dish._id !== dishId);
		setDishes(newDishes);
	};

	return (
		<div>
			{dishes.map((dish, index) => (
				<div key={dish._id}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "1rem",
							// some gap
							// gap: "1rem",
						}}
					>
						<TextField
							label="Όνομα Πιάτου"
							value={dish.name}
							onChange={(e) =>
								handleDishChange(
									dish._id,
									"name",
									e.target.value
								)
							}
							style={{marginRight: "1rem"}}
						/>
						<RadioGroup
							row
							value={dish.type}
							onChange={(e) =>
								handleDishChange(
									dish._id,
									"type",
									e.target.value
								)
							}
							style={{marginRight: "1rem"}}
						>
							<FormControlLabel
								value="main"
								control={<Radio />}
								label="Κυρίως"
							/>
							<FormControlLabel
								value="pescatarian"
								control={<Radio />}
								label="Νηστίσιμο"
							/>
							<FormControlLabel
								value="dessert"
								control={<Radio />}
								label="Επιδόρπιο"
							/>
						</RadioGroup>
						<IconButton
							onClick={() => handleRemoveDish(dish._id)}
							size="small"
							color="error"
						>
							<DeleteIcon />
						</IconButton>
					</div>
					{index !== dishes.length - 1 && (
						<Divider variant="middle" sx={{mb: "1rem"}} />
					)}
				</div>
			))}
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					marginTop: "1rem",
				}}
			>
				<Button
					color="primary"
					onClick={handleAddDish}
					sx={{mb: "1rem"}}
				>
					Προσθηκη +
				</Button>
			</div>
		</div>
	);
});

export default DishForm;
