import {createContext, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

const context = createContext(null);

function UserProvider({children}) {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [error, setError] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		fetchUser();
	}, []);

	async function fetchUser() {
		try {
			const res = await fetch("/api/auth/user");
			const data = await res.json();

			if (data.user) {
				setUser(data.user);
				setIsAuthenticated(true);
			} else {
				setIsAuthenticated(false);
			}
		} catch (err) {
			setError(err.message || "Failed to fetch user.");
		} finally {
			setLoading(false);
		}
	}

	async function makeRequest(url, options = {}) {
		const response = await fetch(url, {
			...options,
			headers: {
				"Content-Type": "application/json",
				...options.headers,
			},
		});

		const data = await response.json(); // Fetch the response data right here

		if (!response.ok) {
			throw new Error(data.message || "Network response was not ok."); // Use the server's message if available
		}

		// if (data.message) {
		// 	throw new Error(data.message);
		// }

		return data;
	}

	async function login(username, password) {
		try {
			const response = await makeRequest("/api/auth/login", {
				method: "POST",
				body: JSON.stringify({username, password}),
			});

			if (response.success && response.data.user) {
				setUser(response.data.user);
				setIsAuthenticated(true);
				navigate("/diaxirisi");
			} else {
				setError(response.message || "Login failed.");
				throw new Error(response.message || "Login failed.");
			}
		} catch (err) {
			setError(err.message || "Login failed.");
			throw new Error(err.message || "Login failed.");
		}
	}

	async function logout() {
		try {
			await makeRequest("/api/auth/logout", {method: "POST"});
			setUser(null);
			setIsAuthenticated(false);
			navigate("/");
		} catch (err) {
			setError(err.message || "Logout failed.");
		}
	}

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<context.Provider
			value={{
				user,
				login,
				logout,
				isAuthenticated,
				error,
				setIsAuthenticated,
			}}
		>
			{children}
		</context.Provider>
	);
}

export {UserProvider, context as UserContext}; // Exporting context too for direct use if needed
