import {useEffect, useState} from "react";
import {
	Table,
	TablePagination,
	Checkbox,
	IconButton,
	TableRow,
	TableCell,
	Typography,
	TableBody,
	Paper,
	Container,
	Stack,
	Button,
	Card,
	TableContainer,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {MenuListHead} from "./components/menu";
import {filter} from "lodash";

import DeleteConfirmationDialog from "../input/DeleteConfirmationDialog";
import {useSnackbar} from "../../contexts/SnackbarProvider";
import {SideListToolbar} from "./components/sides";
import DynamicDialog from "./components/DynamicDialog";

// components
import Scrollbar from "./components/scrollbar";
import axios from "axios";

const TABLE_HEAD = [
	{id: "order", label: "Σειρά", alignRight: false},
	{id: "name", label: "Όνομα", alignRight: false},
	{id: "restaurant_price", label: "Τιμή Εστιατορίου", alignRight: false},
	{id: "package_price", label: "Τιμή Πακέτου", alignRight: false},
	{id: ""},
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});

	if (query) {
		return filter(
			stabilizedThis.map((el) => el[0]), // Filter the stabilized array
			(_side) => {
				const loweredQuery = query.toLowerCase();
				return (
					_side.name &&
					_side.name.toLowerCase().indexOf(loweredQuery) !== -1
				);
			}
		);
	}
	return stabilizedThis.map((el) => el[0]);
}

function Sides() {
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("order");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [filterName, setFilterName] = useState("");
	const [selected, setSelected] = useState([]);
	const [sides, setSides] = useState([]);
	const [targetSideId, setTargetSideId] = useState(null);
	const [editingItem, setEditingItem] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);

	const formFields = [
		{
			type: "text",
			name: "name",
			label: "Όνομα",
		},
		{
			type: "text",
			name: "restaurant_price",
			label: "Τιμή Εστιατορίου",
		},
		{
			type: "text",
			name: "package_price",
			label: "Τιμή Πακέτου",
		},
		{
			type: "text",
			name: "order",
			label: "Σειρά",
		},
	];

	const showSnackbar = useSnackbar();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = sides.map((n) => n._id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const handleOpenDeleteDialog = (id) => {
		setTargetSideId(id);
		setOpenDeleteDialog(true);
	};

	const handleEditClick = (item) => {
		setDialogOpen(true);
		setEditingItem(item);
	};

	const handleDelete = () => {
		// Use targetDishId to delete the dish, e.g., by making an API call.
		axios
			.delete(`/api/sides/${targetSideId}`)
			.then((res) => {
				if (res.status === 200 && res.data.success) {
					showSnackbar("Το συνοδευτικό διαγράφηκε", 4000, "success");
					refreshMenu();
				} else {
					showSnackbar("Κάτι πήγε στραβά", 4000, "error");
				}
			})
			.catch((err) => {
				console.log(err);
			});

		// After the deletion, clear the targetDishId and close the dialog
		setTargetSideId(null);
		setOpenDeleteDialog(false);
	};

	const handleMultipleDelete = () => {
		axios
			.delete("/api/sides/delete-sides", {data: {sides: selected}})
			.then((response) => {
				if (response.data.success) {
					showSnackbar("Τα συνοδευτικά διαγράφηκαν", 4000, "success");
					refreshMenu();
					return;
				}
				showSnackbar(response.data.message, 4000, "error");
			})
			.catch((error) => {
				showSnackbar(error.message, 4000, "error");
			});

		setSelected([]);
	};

	const refreshMenu = () => {
		axios
			.get("/api/menus/full-menu")
			.then((res) => {
				const parsedData = res.data.sides;
				setSides(parsedData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		refreshMenu();
	}, []);

	const submitEdit = (data) => {
		if (editingItem) {
			axios
				.put(`/api/sides/${editingItem._id}`, {side: data})
				.then((res) => {
					if (res.data.success) {
						showSnackbar(
							"Το συνοδευτικό ενημερώθηκε",
							4000,
							"success"
						);
						refreshMenu();
						setDialogOpen(false);
						setEditingItem(null);
					} else {
						showSnackbar("Κάτι πήγε στραβά", 4000, "error");
					}
				})
				.catch((err) => {
					console.log(err);
					showSnackbar("Κάτι πήγε στραβά", 4000, "error");
				});
		} else {
			axios
				.post("/api/sides", {side: data})
				.then((res) => {
					if (res.data.success) {
						showSnackbar(
							"Το συνοδευτικό δημιουργήθηκε",
							4000,
							"success"
						);
						refreshMenu();
						setDialogOpen(false);
					} else {
						showSnackbar("Κάτι πήγε στραβά", 4000, "error");
					}
				})
				.catch((err) => {
					console.log(err);
					showSnackbar("Κάτι πήγε στραβά", 4000, "error");
				});
		}
	};

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sides.length) : 0;
	const filteredSides = applySortFilter(
		sides,
		getComparator(order, orderBy),
		filterName
	);
	const isNotFound = !filteredSides.length && !!filterName;

	const table = (
		<>
			<Table>
				<MenuListHead
					order={order}
					orderBy={orderBy}
					headLabel={TABLE_HEAD}
					rowCount={sides.length}
					numSelected={selected.length}
					onRequestSort={handleRequestSort}
					onSelectAllClick={handleSelectAllClick}
				/>
				<TableBody>
					{filteredSides
						.slice(
							page * rowsPerPage,
							page * rowsPerPage + rowsPerPage
						)
						.map((row) => {
							const {
								_id,
								name,
								restaurant_price,
								package_price,
								order,
							} = row;
							const selectedSide = selected.indexOf(_id) !== -1;

							return (
								<TableRow
									hover
									key={_id}
									tabIndex={-1}
									role="checkbox"
									selected={selectedSide}
								>
									<TableCell padding="checkbox">
										<Checkbox
											checked={selectedSide}
											onChange={(event) =>
												handleClick(event, _id)
											}
										/>
									</TableCell>

									<TableCell align="left">
										{order || "-"}
									</TableCell>

									<TableCell
										component="th"
										scope="row"
										padding="none"
									>
										<Typography variant="subtitle2" noWrap>
											{name}
										</Typography>
									</TableCell>

									<TableCell align="left">
										{restaurant_price || "-"}
									</TableCell>

									<TableCell align="left">
										{package_price || "-"}
									</TableCell>

									{/* <TableCell align="right">
										<IconButton
											size="large"
											color="inherit"
											onClick={handleOpenMenu}
										>
											<MoreVertIcon />
										</IconButton>
									</TableCell> */}
									<TableCell align="right">
										<IconButton
											// size="large"
											sx={{color: "info.main"}}
											onClick={() => {
												handleEditClick(row);
											}}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											// size="large"
											sx={{color: "error.main"}}
											onClick={() =>
												handleOpenDeleteDialog(_id)
											}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
				{emptyRows > 0 && (
					<TableRow style={{height: 53 * emptyRows}}>
						<TableCell colSpan={6} />
					</TableRow>
				)}

				{isNotFound && (
					<TableBody>
						<TableRow>
							<TableCell align="center" colSpan={6} sx={{py: 3}}>
								<Paper
									sx={{
										textAlign: "center",
										boxShadow: "none",
									}}
								>
									<Typography variant="h6" paragraph>
										Δεν βρέθηκαν συνοδευτικά
									</Typography>

									<Typography variant="body2">
										Δεν βρέθηκαν συνοδευτικά για &nbsp;
										<strong>
											&quot;{filterName}
											&quot;
										</strong>
										.
										<br /> Ελέγξτε την ορθότητα της
										αναζήτησης ή δοκιμάστε να
										χρησιμοποιήσετε έναν διαφορετικό όρο
										αναζήτησης.
									</Typography>
								</Paper>
							</TableCell>
						</TableRow>
					</TableBody>
				)}
			</Table>

			<TablePagination
				rowsPerPageOptions={[5, 10, 25]}
				component="div"
				count={sides.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage="Παραγγελίες ανά σελίδα"
				labelDisplayedRows={({from, to, count}) =>
					`${from}-${to} από ${count}`
				}
			/>

			<DeleteConfirmationDialog
				open={openDeleteDialog}
				onClose={() => setOpenDeleteDialog(false)}
				onConfirm={handleDelete}
				title="Διαγραφή συνοδευτικού"
				description="Είστε σίγουρος για την διαγραφή του συνοδευτικού;"
			/>
		</>
	);

	return (
		<>
			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
						Διάφορα
					</Typography>
					<Button
						variant="contained"
						startIcon={<AddIcon />}
						onClick={() => setDialogOpen(true)}
					>
						Προσθήκη
					</Button>
				</Stack>

				<Card>
					<SideListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
						handleMultipleDelete={handleMultipleDelete}
					/>

					<Scrollbar>
						<TableContainer sx={{minWidth: 800}}>
							{table}
						</TableContainer>
					</Scrollbar>
				</Card>
				<DynamicDialog
					open={dialogOpen}
					handleClose={() => {
						setDialogOpen(false);
						setEditingItem(null);
					}}
					formFields={formFields}
					initialValues={editingItem}
					onSubmit={submitEdit}
					title={
						editingItem
							? "Επεξεργασία συνοδευτικού"
							: "Προσθήκη συνοδευτικού"
					}
				/>
			</Container>
		</>
	);
}

export default Sides;
