// @mui
import {alpha, styled} from "@mui/material/styles";
import {Card, Typography} from "@mui/material";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({theme}) => ({
	margin: "auto",
	display: "flex",
	borderRadius: "50%",
	alignItems: "center",
	width: theme.spacing(8),
	height: theme.spacing(8),
	justifyContent: "center",
	marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

export default function WidgetSummary({
	title,
	total = 0,
	icon,
	color = "primary",
	sx,
	type = "number",
	...other
}) {
	return (
		<Card
			sx={{
				py: 5,
				boxShadow: 0,
				textAlign: "center",
				color: (theme) => theme.palette[color].darker,
				bgcolor: (theme) => theme.palette[color].lighter,
				...sx,
			}}
			{...other}
		>
			<StyledIcon
				sx={{
					color: (theme) => theme.palette[color].dark,
					backgroundImage: (theme) =>
						`linear-gradient(135deg, ${alpha(
							theme.palette[color].dark,
							0
						)} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
				}}
			>
				{icon}
			</StyledIcon>

			<Typography
				variant="h3"
				sx={{
					color: (theme) =>
						theme.palette[color].darker ??
						theme.palette[color].dark,
				}}
			>
				{total &&
					type === "currency" &&
					new Intl.NumberFormat("el-GR", {
						style: "currency",
						currency: "EUR",
					}).format(total)}

				{total && type === "number" && total.toLocaleString()}

				{total && type === "string" && total}
			</Typography>

			<Typography
				variant="subtitle2"
				sx={{
					opacity: 0.72,
					color: (theme) =>
						theme.palette[color].darker ??
						theme.palette[color].dark,
				}}
			>
				{title}
			</Typography>
		</Card>
	);
}
